module.exports = [
  {
    id: 1,
    title: "Very Solid!!",
    rating: 5,
    text: "I have been using the services of Wayonc Investment for the past 3 years and have seen them evolve. One thing that they have been consistent about is not to time the market, which removes the stress out of investing. In the last few years their recommendations have been very profitable. They are conservative and don't encourage us to get carried away by price increases due to speculation- this is a good thing. All in all, i don't need to spend extra time researching and can trust them to do their job well. ",
    author: "Rashed Kabir",
    location: "Claifornia",
    image: "/images/media/google-logo.png",
  },
  {
    id: 2,
    title: "Very Solid!!",
    rating: 5,
    text: "Few years back, like any beginner I started investing, which turned out to be speculative trading based on news and speculations. Wayonc Investment brought method to madness for me. It taught me that like any other field, investing too require deep research and understanding of underlying fundamentals. Wayonc Investment helped me to develop my investment philosophy. The 15 year X-Ray of a company does 90% of things for selecting a stock to invest in. I have been able to make profitable investments thanks to Wayonc Investment. I sincerely appreciate the work Wayonc Investment is doing and wish them well for future. ",
    author: "Vijay Kumar",
    location: "Claifornia",
    image: "/images/media/google-logo.png",
  },
  {
    id: 3,
    title: "Very Solid!!",
    rating: 5,
    text: "Wayonc Investment is an excellent adviser on equity and mutual funds. I found excellent support from their team. It gives complete analysis of individual stocks and what is the logic and why it is recommended. One needs to have more than 3 years horizon to invest in stocks and mutual funds and risk associated with it is also minimized. I found Wayonc very useful adviser. stocks and mutual funds recommended are not biased. It allows you to invest and have peaceful sleep without getting too much concerned about market volatility.",
    author: "Sanjay Kumar",
    location: "Claifornia",
    image: "/images/media/google-logo.png",
  },
  {
    id: 4,
    title: "Very Solid!!",
    rating: 5,
    text: "The overall experience is very good. The hand holding support that Money works for me has provided to a novice investor like me was critical in building my portfolio over the last two years. The analysis and notes sent by them are written in simple language with examples and seem to be useful for understanding both the larger picture as well as details of a specific company. Some points of improvement they may consider are with their website & app interface. I found it a little non intuitive. A lot of information is displayed at once on the screen which the user may not need that frequently.",
    author: "Ramesh ",
    location: "Claifornia",
    image: "/images/media/google-logo.png",
  }
];
