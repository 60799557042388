export const intradayTradingData = {
  title: 'INTRADAY TRADING TIPS',
  body: [
    '<h4>Best Intraday Tips Strategy</h4>',
    '<h6>Looking for top Intraday Tips Provider in India..?</h6>',
    '<p>Searching for the Best Intraday Tips Site can be quite a hard task. Intraday trading offers you the potential to grow your capital every day and earn consistent profits. However- it is not as easy as it sounds. Day trading, as it is commonly known, can be quite risky if done without adequate knowledge and research.</p>',
    '<p>With so many stocks to choose from, a myriad of news and data- how can one make sense out of it all and use it to consistently grow one’s capital? The answer is simple- WayonC’s Intraday Trading Tips Site.</p>',

    '<h5>Why Intraday Trading?</h5>',
    '<p>The first question that will come to your mind would probably be “why should you go for intraday trading“. A few advantages that you get with intraday trading as compared to other types of investments are ahead.</p>',
    '<h5>1. Low Capital Requirement</h5>',
    '<p>You can start your day trading journey with low capital and earn sizable profits by following our intraday trading tips. As you earn profits, you can also earn higher leverage on available capital thus letting you trade more actually putting down all your capital.</p>',
    '<h5>2. No Overnight Risk</h5>',
    '<p>With intraday trading, there is no form of overnight risk. Thus, your trades are unaffected by any events that take place during the night. Any stock market event- be it local or global, will not be able to have an impact on your trades at night when you cannot monitor it.</p>',
    '<h5>3. Low Brokerage Fees</h5>',
    '<p>With intraday trading, the brokerage and commission fees are less. This is because the delivery expenses involved in the transfer of securities are eliminated. This increases your overall profit as you have to pay less commission to the broker. In many cases, it can be just 1/10th of what you would pay in standard trading.</p>',
    '<h5>4. Higher Profits</h5>',
    "<p>With intraday trading, the potential profits are higher. You can trade intraday in both bearish and bullish markets depending on your strategy. It is also known to create massive wealth for investors using the right strategies and following today's intraday tips.</p>",
    '<h5>5. Higher Liquidity</h5>',
    '<p>With intraday trading, financial resources that are invested can be recovered at any time. They are not blocked in any transaction. Thus, your liquidity is preserved so that you can meet any needs that require liquid funds.</p>',
    '<p>Thus, all these advantages come together to make intraday trading a high-stakes game where you can get profits every day. However- how do you go about it? What can WayonC® help you do in this?</p>',
    '<h6>What Makes WayonC® The Best Intraday Tips Site</h6>',
    '<p>Before you start going at Intraday trading on your own, you need to understand the risks that come with it. Intraday trading has a higher risk factor- considerably higher than commodity and stock trading. And that is not all-you also risk not getting profits that match the risk and effort, especially since a large part of intraday investments are in small and mid-cap stocks. The opportunity to earn does exist- but you need the best intraday tips to make that happen consistently. This is where WayonC® team of seasoned and expert investors comes in to help the intraday trader of every type and risk appetite. A few key points that separate our tips from the rest and make us the service with the best intraday tips are discussed ahead</p>',
    '<h6>Quantitative and qualitative analysis</h6>',
    '<p>The inexperienced trader gives tips or trades as per a single factor or in reaction to some event. However, that is just a small piece of the big picture. Any accurate analysis of the market calls for qualitative and quantitative analysis. Every aspect needs to be considered before sending out even the smallest of intraday trading tips- and that is the approach that our expert team of trading advisors follows. Every tip is backed by extensive research and analysis, including the history of the stock and the pulse of the market.</p>',
    '<h6>Team of veterans with significant experience</h6>',
    '<p>Every team member at WayonC® has been trading and studying the market for years. These veterans have made significant profits and are now dedicated to helping you do that. With every intraday tip that you receive from us, you can be sure of it being backed by the most extensive analysis and research.</p>',
    '<p>Tips that cover every possible scenario All our tips include entry as well as exit prices. We also ensure that we guide our customers so that they do not suffer from the buyer’s fallacy. Stop-loss levels are also suggested to ensure that your losses are capped.</p>',
    '<h6>Educating the investor</h6>',
    '<p>At WayonC®, we believe in having the maximum knowledge before making any investment. This is why we do not just share the best intraday tips- but we ensure that our clients understand the fundamental strength of every stock that they are about to invest in. This also helps them to decide whether to convert any unsold stock into delivery. Even the rarest of scenarios are covered with our approach so that you are ready to make any type of decision at the right time.</p>',
    '<p>We also provide a comprehensive insight into the organization, including its history, merger, bonuses, splits, etc. All these factors are key to deciding the stock prices, and we ensure that you are appraised of them all in time.</p>',
    '<h6>The WayonC® Intraday Trading Approach</h6>',
    '<p>At WayonC®, we base our picks based on a mix of qualitative and quantitative analysis. The key points of our strategy in sending you the best intraday tips are discussed ahead.</p>',
    '<h6>1. Choosing Liquid Stocks</h6>',
    '<p>As intraday trading requires buying and selling on the same day, we ensure that we choose highly-liquid stocks so that you can square off the same day and minimize your chances of taking delivery.</p>',
    '<h6>2. Frozen Entry and Exit Prices</h6>',
    '<p>We ensure that you get a specific range of entry and exit prices so that you know the exact moment to enter into the trade and when to square off.</p>',
    '<h6>3. Stop Loss Levels</h6>',
    '<p>To minimize losses and your capital, we share the stop-loss level as well so that you are prepared to handle any situation that the market may take you into.</p>',
    '<h6>4. Thorough Research of Target Stocks</h6>',
    '<p>Every tip that we share is backed by extensive research of the target company, its sector, and all factors that impact it. This ensures that we share tips with maximum accuracy.</p>',
    '<h4>What should you know before you start trading intraday?</h4>',
    '<p>Like any other form of trading, there are a few things that you must know before you start intraday trading. Combining these easy-to-follow guidelines with our best intraday tips can help you formulate a profitable trading strategy.Set aside your day-trading capital</p>',
    '<p>You must never invest all your money in day trading or in a single stock. Have a separate portion of your capital for day-trading and diversify it- do not put it all in one trade.</p>',
    '<h6>Start small</h6>',
    '<p>If you are just getting started with intraday trading, it is fine to go with a small amount or one 1-trade a day to understand what you are getting into. Even for experienced traders, it is good to pick quality over quantity- 1 or 2 intraday trades every day are sufficient if backed by thorough research.</p>',
    '<h6>Knowledge is power</h6>',
    '<p>With any form of trading, especially intraday, knowledge is power. You cannot expect consistent profits until you back each trade with adequate knowledge and research. Our intraday trading tips aim to ease the burden on you and are backed with extensive analysis and research to provide you with accurate tips for a growth-oriented portfolio</p>',
    '<h6>Limit Orders</h6>',
    '<p>You never know what turn the market may take at any moment. Always include stop-loss values in your orders so that you are prepared in case things do not go your way. As part of our best intraday tips, we always include stop-loss to keep you prepared for any situation.</p>',
    '<h6>Follow the right sources</h6>',
    '<p>With the internet, there is a lot of ‘noise’ that surrounds the knowledge you need for trading. It is important to filter it out so that you can follow the right news and facts before deciding on a trade. At WayonC®, we take care of all that for you and ensure that each tip is backed by the most extensive analysis and research.</p>',
    '<p>Intraday trading is profitable only if you follow the right guidance</p>',
    '<p>In the search for the best intraday tips, traders often fall prey to claims like high profits from day one. However- only the trader with investments backed by expertise and experience wins here. We understand the value of your hard-earned money and what profits mean for you- which is why we do not make any false claims.</p>',
    '<p>Compared to any other intraday trading tips provider, we provide tips with high accuracy, having an accuracy of 70- 80% consistently. You do not need to take our word for that- you can try out our services and feel the difference as we help you get considerable margins in your trading. Reach out to us today and learn about what the best intraday trading tips can help you earn.</p>',
  ],
};
export const ShareMarket = {
  title: 'BEST SHARE MARKET TIPS',
  body: [
    "<Image src='' alt=''/>",
    '<p>When it comes to the share market, it presents a galore of opportunities for sellers and buyers. Both the NSE and BSE list a total of nearly 6000 companies of India. Millions of buyers and sellers try to negotiate with each other about the pricing of the stocks. Needless to say, but there are tons of famous people who have earned a sizable income from the share market. However, your success in the share market is largely determined by two factors.One is your due diligence in the research about the organization and second is share market tips.</p>',
    '<h6>Implementing correct share market tips is the key to success</h6>',
    '<p>The reason why so many people lose in the share market is that they rely on the advice of the unprofessional people, who themselves have not earned any concrete profit in the share market. While it is true that our research and analysis will certainly help you out in making the right decision, we love clients who are willing to do their due diligence. If you think that you are ready to a devout a considerable time and capable of implementing share market tips, our professional guidance will certainly help. Hire us to help you win in the share market.</p>',
    '<h6>So where is the need for a broker in this scenario?</h6>',
    '<p>At WayonC, we take the responsibility of effort and wise judgment upon ourselves and suggest the stocks to the clients which stand at a high chance of making a profit.</p>',
    '<p>You may have negative notions about the share market but in reality, common sense and persistent effort are the only things you need to earn some great profit. A lot of people have done it and you can too. You simply need the right guidance who can make your task easier in research, analysis; and in buying and selling of the stock at the crucial time</p>',
    '<h6>So why WayonC?</h6>',
    '<p>We clearly explain to our users the correct share market tips, so our users have a minimum chance of losing the money. While we do not guarantee any results, the success of our clients speaks volume about the accuracy of our predictions, with consistency over the years.</p>',
    '<p>A lot of brokers are not really honest when they suggest the clients to buy and sell their stocks. Most of them are after the commission and this lures them to give suggestions, which may not be in the best interest of their clients. But at WayonC, we stand apart from the rest of the broker in the following ways.</p>',
    '<p>We explain PE or Profit Earning ratio of Nifty and Sensex along with other factorswhich regulate the upward and downward spiral of the stocks of various organizations.</p>',
    '<p>Helps them to set the selling price of the stocks.We give out an in-depth analysis of the trending factors based on market research.And we disclose a ton of other information that most brokers don’t tell to their clients. You may ask why? Because disclosing such regulating factors would not help them to acquire more money from their clients and hence they won’t be able to earn the commission.</p>',
    '<p>Our seasoned professionals have seen it all throughout the years and therefore understand the pulse of the market very deeply. Whether you are an amateur while implementing the share market tips & tricks or want to rely completely on it to earn your bread and butter, we have the</p>',
    '<p>knowledge, expertise and honest intentions to help you reach your goal. So many of our clients have achieved immense success with the help of our advice.</p>',
  ],
};
export const Commodity = {
  title: 'Commodity Trading Tips',
  body: [
    '<h6>Commodity Tips: The Key To Growing your Portfolio</h6>',
    '<p>Simply looking for commodity trading tips will not help you earn a profit- you need to understand what market of commodities works best for you, your risk appetite, your goals, and then take the help of experts who have been studying the market for years. WayonCcan help you with all that and much more.</p>',
    '<h6>Who can benefit from our commodity trading tips?</h6>',
    '<p>Commodity markets are unlike any other form of investment. Contrary to popular belief, the commodity market should be used for the exchange of goods of four different categories hedging. Two exchanges are available for commodity trading- the MCX (Multi-commodity Exchange) and the NCDEX (National Commodity Derivative Exchange). Just like in other markets, there are numerous factors which affect the upward and downward spiral of the commodities. And this is where we, WayonChelps our clients.</p>',
    '<h6>Why commodity trading?</h6>',
    '<p>Investing in commodity trading is one of the best ways to protect your investment against inflation. With the right Commodity Market Tips, you can secure your investment with guaranteed growth. It is also one of the best things you can do as part of the diversification of your investment portfolio. Metals like gold and silver, agriculture products, and energy are all sound investments with guaranteed growth- but only when you follow the right strategy. High leverage, lower price manipulation, and a transparent process- all these are reasons why it may be the right investment choice for you</p>',
    '<p>However, for the lone investor, learning how commodities move, what to track and what to ignore, and how to make sense of the plethora of data out there- all this can be a daunting task. WayonCtakes care of all that and provides you with the most accurate of commodity trading tips that will help your investment to grow.</p>',

    '<h6>The WayonCAdvantage</h6>',
    '<p>Unlike stocks and other funds, the commodity market fluctuates owing to different parameters. At times, certain trade decisions between the countries can also increase both liquidity and volatility. With influencing factors playing at such a large and global scale, having professionals with the necessary experience and expertise to guide you can help you make the right investment decisions.</p>',
    '<p>Live MCX Market Tips through SMS and call. Almost 85% accuracy rate across all commodity trading tips</p>',

    '<p>A team of seasoned professionals behind every research and analysis task.</p>',

    '<p>These professionals are focused on the influencing parameters which affect the prices of the commodities. Trades between companies, bank debts, mergers, and all sorts of news are observed with keen interest by our team.</p>',
    '<h6>A dedicated relationship manager with daily communication.</h6>',

    '<p>All commodity market tips backed by high quality qualitative and quantitative technical analysis of the commodities listed in MCX and NSDEX.</p>',
    '<p>Who can benefit from our commodity market tips?</p>',
    '<p>Our commodity tips are ideal for beginners, intermediates, and even the seasoned traders. We have veterans for commodity training tips with a proven track record. These professionals are focused on helping your investment grow and minimizing any risk.</p>',
    '<h6>Know all the facts before investing</h6>',
    '<p>You may have heard about people losing their hard-earned money in commodity training. Not following the right commodity tips and improper due diligence for research and analysis is the major reason for that. Expert advisors and data can help you a lot- and that is what you get with every tip shared by WayonC. If you are ready to step into the world of growth, sound investments, and consistent profits, then it is time you reached out to us at 0281-6199999.</p>',
  ],
};
export const shareTrading = {
  title: 'Stock Market Trading Tips',
  body: [
    "<Image src='' alt=''/>",
    '<h6>How to start</h6>',
    '<p>The share market can be very unpredictable at times. People make or break their fortunes in it. Therefore, to tread in the murky waters of the share market you need to move with caution. In this article, we will share with you some valuable share trading tips that can help you become a successful share market trader and investor. So, let’s get the things rolling. We will first know what is a share market and how it functions before we give you our share tips.</p>',
    '<h6>What is the share market?</h6>',
    '<p>A share market or a stock market or a stock exchange is a place where shares of public limited companies are bought and sold. A public limited company raises capital for its operations from the general public. If you own shares of a certain public company, you partly own it and can trade its shares on the stock market.</p>',
    '<h6>Share trading tips:</h6>',
    '<ul><li>Now that we have understood two basic terms about shares, let’s start with our share trading tips.</li><li>You should have a proper plan in place:</li><li>Before you invest in the stock market, ask yourself these basic questions;</li><li>Why do you want to invest in the share market? What are your goals?</li><li>Do you know the fundamentals of trading in the share market</li><li>Will you be a day trader, buying and selling for short term gains or an investor with the long term in mind?</li></ul>',
    '<h6>What are the risks associated with investing and trading in the stock exchange? What are your expenses?</h6>',
    '<p>These questions will help you in setting up your path, knowing your limitations, and achieving your ultimate goals.</p>',
    '<h6>Start with small:</h6>',
    '<p>It is always a good idea to start with a small capital instead of investing heavily. As a rule of thumb, you can invest in the stock market after setting aside money for the following;</p>',
    '<h6>Your monthly expense</h6>',
    '<ul><li>You can invest the remainder in the share market.</li><li>Interests or loan repayments</li><li>for education, entertainment purpose</li><li>Emergency funds for healthcare etc.</li></ul>',
    '<h6>Research:</h6>',
    '<p>If you are new to the stock exchange you must study it thoroughly and understand how it operates. Seek as much knowledge as you can. You can browse online for useful resources on this, consults friends and professionals, and gain insight from any other sources. You can hire a good share market advisor who can guide you on share market tips against a small fee. Here’s our list of important things you need to learn if you want to be a successful stock market investor.</p>',
    '<p>Technical terms & metrics: When you enter the stock market you will hear various words, phrases, and terms that might seem alien to you. For example, terms like bullish, bearish, blue chips, IPO, etc. are part of daily parlance in the world of stocks. You should know their meaning and interpretation.</p>',
    '<p>Likewise, you should know how to see and analyze a company’s financial statements. What are the financial metrics that can help you whether to buy or sell a company’s shares? These may include return on investment (ROI), earning per share (EPS), compound annual growth rate (CAGR), etc</p>',
    '<p>Stock selection: While selecting what stocks to buy, look for companies with good business standing, and higher dividends. These are called blue chips and are always in demand. Do not go for sectors that are currently thriving but don’t have sustainability. Similarly, do not jump on low-priced stocks. These are priced low for some reason. Consider all factors and make a prudent economic decision. By reading the share market section of the newspaper you can get a lot of share recommendations.</p>',
    '<h6>Self-financing vs third party financing:</h6>',
    '<p>If you do not have funds but still want to invest in the stock market you can do so by taking a loan from the bank or the brokerage house. Usually, a loan for up to 50% of your investment plans can be arranged. For example, if you want to invest US$5,000 in the stock market, you can get a loan for US$2,500 for it. You need to beware and take a loan only when you are reasonably sure that your investment in stocks will pay off handsomely enabling you to pay back your loan and earn some money for yourself too.</p>',
    '<h6>Self-trading vs third part trading:</h6>',
    '<p>If you have the required knowledge you can easily trade-in shares sitting at home or in your office. Everything is online these days. If on the other hand, you lack the expertise to trade on your own you can hire the services of a broker. A broker is like a share market advisor. He will advise you what to buy and what to sell and when it is right to do this. He will give you valuable share market tips. He will of course charge you commission for his services.</p>',
    '<h6>Risk tolerance:</h6>',
    '<p>Before investing in the stock market understand and know your level of risk tolerance. Simply put, your risk tolerance is your level of comfort or anxiety in an unfavorable situation. It is mostly inbuilt but factors like age, perception, education, and environment, etc. influence it. If your risk tolerance is high, you can likely make bigger decisions with higher risks and consequently higher returns. Your investment decisions in the stock market depend on your level of risk tolerance.</p>',
    '<h6>Diversification or not?</h6>',
    '<p>Anybody would advise you not to invest too heavily in one company or one sector in the stock market. It makes sense too. It’s among the popular share trading tips andit makes sense too.If the company or that sector suffers losses, you will incur heavy losses too. But each coin has two sides. By diversifying your investment portfolio into different sectors and different companies you have to be vigilant of the current trends impacting them. It can sometimes get difficult. So, a balanced approach is advisable where you diversify but to the extent that you can keep track of your investment portfolio.</p>',
    '<h6>Plan your trading decision:</h6>',
    '<p>While doing investment in shares make a thorough analysis of the situation and do not go for impulsive buying or selling. It could result in a loss. We provide you some share market tips on buying and selling.</p>',
    '<h6>Share trading tips for buying:</h6>',
    '<p>You should know why are you buying shares of a particular company? What is it that you find good? What are your expectations and will you hold the investment for long or short term benefits? Weigh your buying decision on credible metrics. Study the previous financial statements of the company. Work out important ratios and also outline the risk factors and how you will cope with them.</p>',
    '<h6>Share trading tips for selling:</h6>',
    '<p>When it comes to selling you should know why are you selling your stocks? Is it because you planned to sell it after some time? Do you believe the company is losing value? If so, what are the reasons? Do you expect some change in the way the company does business or a major policy change by the government will affect the company’s standing? There could be many reasons for selling and yours should make good economic sense.</p>',
    '<p>In a nutshell, we can say buy at the right time and sell at the right time. You should know the real worth of your share. Do not always sell a share when it is hitting low if it has the potential to rebound it will. Panic buying and selling are not an option. This is one of the important share tips.</p>',
    '<h6>Do not over trade:</h6>',
    '<p>In a rush to make large profits many investors make this lethal mistake of overtrading. They overstretch their limits and their resources. This puts them in a very precarious position and when the crunch comes they lose everything. So, always be rational and do not chew more than you can. Greed is your worst enemy, know this, and do not succumb to temptations.</p>',
    '<h6>Try long term investments:</h6>',
    '<p>Like we discussed in the beginning that you should know whether you want long term benefits or short term gains, while the choice is yours we, however, would advise you to stick to long term. Investing in stocks can reap huge benefits in the long run. You can not only earn dividends but also sell shares at a premium. Seek share recommendations from experts and invest in them for the long term.</p>',
    '<h6>Be realistic:</h6>',
    '<p>Another important thing to remember is to be realistic with your stock market plans. Do not expect miracles to happen overnight. You will be rewarded in due course. Patience and perseverance is the key. Also, keep the expectation of profit margin at the lower level. Markets can tumble and go awry. The best estimations can go wrong. So, always account for such unforeseen eventualities.</p>',
    '<h6>Stay calm and cool:</h6>',
    '<p>Investing in the stock market is not for the squeamish. Expect crashes and meltdowns. They happen and test your nerves. Do not get panicky under such circumstances and maintain your composure. Stress won’t help you make things better. Just wait for the things to turn back to normal. Do not try to compensate your losses by taking high-risk decisions that you are not familiar with. It is wise to adjust your investments according to changing market trends but do not do it in a rushed and haphazard manner with no plan in sight.</p>',
    '<p>You should always maintain a low, moderate, and high-risk investments. Play it safe with low-risk investments and invest in them. You can study the market and know how to get low-risk share recommendations. For others make a detailed analysis and invest moderately in them. Make a plan for what you expect from these investments and when will you would like to realize them. They are likely to benefit you more in the long term.</p>',
    '<h6>Conclusion:</h6>',
    '<p>So, these are some of the share tips that we think can benefit you if you want to earn handsomely from the stock market. You have to remain focused, patient, and determined in your efforts. Avoid making rash and illogical decisions and keep an eye on the emerging and changing market trends. You will taste success if you keep these important aspects in mind while trading in the stock market. You will be your own share market advisor.</p>',
  ],
};
export const stockMarket = {
  title: 'Share Market Investment TipsS',
  body: [
    "<Image src='' alt=''/>",
    '<h6>Stock Market Tips for Beginners</h6>',
    '<p>As a beginner and most probably a small investor, you need some sort of Stock Market Tips to help you glide through the stock market. Stock markets lure investors into making big but not everyone could make it. The prime reason being, lack of experience and guidance. So, to better equip you, we are giving some very useful Stock Market Tips that will help you be a successful stock market investor.</p>',
    '<h6>Set your goals and objectives:</h6>',
    '<p>Before you invest in the stock market, you must establish your objective. Why are you going to invest in the stock market? What do you want to achieve and how do you plan to accomplish your objective? Your goals will determine your decisions and it will affect your performance in the stock market.</p>',
    '<h6>The right time to enter:</h6>',
    '<p>Our stock tips start with making your debut in this market. What is the right time to do that? Is it when the stocks are falling and the prices are low? Yes, common sense would dictate so and it is not entirely untrue. But you should study the stock you are willing to invest in. Has it hit the rock bottom? Do not buy any stock that is falling. Sometimes the company is suffering due to some bad decisions by the management. So, do your research before taking the plunge.</p>',
    '<h6>Time to leave:</h6>',
    '<p>Our second stock advice is about the time to leave the stock market. There could be personal or economic reasons that vary from person to person. Do not keep on incurring losses. Sometimes investors stick to their stocks despite losses in the hope that they will rebound. This rebound may or may not happen so instead of aggravating your loss you may want to sell those stocks and purchase something that is more likely to bring profits.</p>',
    '<h6>Fundamentals of stock trading:</h6>',
    '<p>You should learn and understand the basics of trading in stocks. These basics can be divided into the following five parts;</p>',
    '<ul><li> <strong>Entry:</strong> The entry can make all the difference. You can get a definite edge when you make the right entry and the right time.</li><li> <strong>Exit: </strong> With entry, it is equally important that you master the exit strategy. When is the right time to call off the deal and leave? How do you plan to exit? These should all be part of your stock market exit strategy.</li><li> <strong>Profit: </strong> When you make a profit where would you take it and how will you manage it? These are all valid questions that you should have an answer for beforehand.</li><li> <strong>Timing the market:</strong> A common mistake that many investors do and you should avoid is waiting for the ‘right’ time to enter or exit the market. There cannot be perfect timing for any of these and you cannot be one hundred percent accurate. Your time inside the stock market counts more than your timing to enter or leave the market.</li></ul>',
    '<h6>Consider options other than stocks:</h6>',
    '<p>Stock market is a place where people come to buy and sell in companies’ stocks. But there are other options too, where you can try your luck. There are bonds, treasury bills, and mutual funds. Amongst these, mutual funds are a safe and viable investment option. They are not that risky and the returns are very reasonable too. So, try these as well.</p>',
    '<h6>How much to invest?</h6>',
    '<p>Another important one among the Stock Market Tips is how much to invest in it? As a beginner, anyone would tell you to start with small capital. You can also start investing a fixed amount every month. That way you will know the dynamics of the stock market tips and your loss if any would be bearable too.</p>',
    '<h6>Paper trading:</h6>',
    '<p>Another thing that you can try before you invest in a stock market is paper trading. It is a technique where you do not buy or sell stocks. You just note down a paper about the number of stocks you want to buy and their value. Then you monitor their performance and see how they fare. You record the profit and loss to know how far you have succeeded in your estimates or predictions. In this way, you learn without actually making a loss. So, it is a good stock market advisory.</p>',
    '<h6>What to buy?</h6>',
    '<p>In the world of stocks, look for a company with a strong standing, that is paying well to its stockholders and is going strong in its business operations. You can start buying stocks of such companies and if they give you benefit, you may invest more in them. It is prudent to invest further in something that is giving you good returns.</p>',
    '<h6>Cheap is not always good:</h6>',
    '<p>You will get a lot of stock recommendations that are very cheap. Such cheap stocks are called penny stocks. Don’t fall for them. We are not writing them off altogether but they are cheap for a reason. Do proper research before you invest in any penny stocks, else you may suffer a heavy loss.</p>',
    '<h6>Take advice only from experts:</h6>',
    '<p>Stock market – Many people will try to impress you with their free stock market advisory. They will claim to be the ultimate guru of the stock market and give you stock advice. Such pseudo-experts have the potential to ruin you.We recommend you take advice only from people who are experts.</p>',
    '<h6>Avoid new stocks:</h6>',
    '<p>New companies may offer a lot of growth potential. Their businesses might sound fancy and new. Who knows, they will achieve their potential or even outgrow their estimates. But you need to be careful, and our stock future tips to you is that you stick to tried and tested stocks only. Once you have settled in, you can try such new companies and technology start-ups.</p>',
    '<h6>Learn to decipher news :</h6>',
    '<p>There is a bombardment of information and news in today’s world. However, as an investor, you need to differentiate between news and non-news items. Often, the media will be blaring out stories about a non-event or something that has already happened. Separate wheat from the chaff and take what’s relevant and important for you and base your economic and investment decision on that.</p>',
    '<h6>Don’t always go with the flow:</h6>',
    '<p>Among the stock tips, another important one is that do not buy anything that everyone else is buying. This often overvalues the stock and that over-valued stock is like a bubble that will burst sooner or later. The Dotcom companies in the late 90s were one such bubble which busted with a bang and many investors lost their fortune.</p>',
    '<h6>Beware of confirmation bias:</h6>',
    '<p>Confirmation bias can be your worse enemy in the stock exchange. It will give you the wrong stock recommendation and incorrect stock options tips. So do not fall in the trap of confirmation bias and base your investing and business decisions on rational reasons backed by empirical data.</p>',
    '<h6>Know the company:</h6>',
    '<p>Do not buy stocks of those companies whose nature of business is not much known or you can’t predict how good or bad they are going to perform in the next 6 or 12 months. These could be technology-based start-ups or something to that effect. You won’t be able to understand their dynamics and make future predictions about their growth prospects.</p>',
    '<h6>Long-term investing:</h6>',
    '<p>You should decide beforehand whether you want to be a short or long-term stock market investor. Our advice and recommendation are that you aim for long term investing. It will be rewarding for you as you will earn periodic dividends on your stocks and the value of your investment will keep on increasing too. In times of boom sometimes the value of stocks reaches unimaginable highs, giving you huge monetary benefits.</p>',
    '<h6>Diversify:</h6>',
    '<p>Any stock options tips would tell you that you should diversify your investment portfolio. It means do not rely on one company or one sector. Invest in a variety of companies and sectors. This safeguards your investment and offsets the loss in one sector through profit in another.</p>',
    '<h6>Passive vs active investing:</h6>',
    '<ul><li> <strong>Active investing: </strong> It is just the opposite of passive investing. Here you pick the companies and the sectors and weigh stock options. You will have a lot of stock recommendations to choose from. Since you take all the decisions so their success and failure rest with you and you have to take the ultimate responsibility of your decisions.</li><li> <strong>Verdict:</strong> Now the question is which one is better? There is no definite answer to it. You can choose either of the two. If you gave time, knowledge, and expertise you can be an active investor or you can be a passive investor if you lack the time and resources. You can also try a combo of both.</li></ul>',
    '<h6>Patience is the name of the game:</h6>',
    '<p>The stock market is tricky. It is volatile and things can start to go wrong in a matter of a few hours. It is usually the first sector that gets affected due to political disturbances, bad economic management, wrong decision making, and trending global events. When you invest in the stock market, you should be wary and aware of all these factors. There will be trouble and there will be shocks. You have to be patient and exhibit steadfastness. Do not panic or get stressed. It will not solve the problem, so relax and carefully monitor the events and reach a conclusion based on that.</p>',
    '<h6>Stock Market Tips Conclusion:</h6>',
    '<p>Investing in the stock market is not for the squeamish, that much is very clear. It is ideal for a long term investor with insight into the workings of the stocks and a cool and calm attitude. If you remain vigilant and invest wisely with calculated risks, you are bound to taste success in the long run. It is a great source of passive income and will give you a handsome return if you can do it right. Just hang in there, and choose the stocks to invest in wisely and act promptly for maximum profit. We hope Our Stock Future Tips will help you to invest wisely in the stock exchange. We wish you all the best.</p>',
  ],
};
export const commodityTrading = {
  title: 'Commodity Trading Tips That You Can’t Learn From Books',
  body: [
    "<Image src='' alt=''/>",
    '<p>Over the past decade, traders and investors believe the stock market to be one of the best options to invest their savings. The stock market is preferred over any other investing options as it provides good returns and helps build a considerable corpus over time. Commodity trading has established itself to be the second-best option for traders. Commodity trading began in India as a trading option but later on, emerged as a prevalent profession among Indians. This article will take you through commodity trading in a very detailed manner.</p>',
    '<h6>What is Commodity Trading?</h6>',
    '<p>Commodity encloses a group of assets that plays a vital role in supporting our lives, like any other basic necessities, including food, energy etc. Commodity trading includes trading of any commodity on an exchange basis. Their price keeps on fluctuating and is either bought or sold depending on their price. The traders use this price fluctuation to gain profit. All the commodities in India fall under four broad categories, Agriculture, Energy, Metals, and Livestock.</p>',
    '<h6>Where can you invest in Commodities in India?</h6>',
    '<p>Commodity trading is effortless and convenient in India. Anyone can take up commodity trading through exchanges. You can trade in India through six primary commodity exchanges, which include:</p>',
    '<ul><li>1) National Commodity and Derivatives Exchange (NCDEX).</li><li>2) National Multi Commodity Exchange (NME).</li><li>3) Multi Commodity Exchange (MCX).</li><li>4) The Universal Commodity Exchange (UCX).</li><li>5) Indian Commodity Exchange (ICEX).</li><li>6) Ace Derivatives Exchange (ACE).</li></ul>',
    '<p>Before investing in any of these trading commodities, you must have all the relevant details about trading along with the details of the company.</p>',
    '<h6>How to Invest in the Commodity Market?</h6>',
    '<p>You can take up commodity trading in the commodity market through a futures contract. It is a type of contract where you make an agreement to either buy or sell a specific commodity depending on the predicted price. Through the futures market, you can trade in any commodity. The position of a trader in the futures market relies on the movement of the predicted price. For example, a trader would invest in a specific part of the commodity if he expects the cost of that particular commodity to go high. Similarly, the trader will sell a particular part of the commodity if he expects the commodity’s price to go down.</p>',
    '<h6>When is commodity trading done in India?</h6>',
    '<p>Indian commodity markets are open on all days of the week except for weekends and public holidays. Market timings of the commodity markets in India depend on the commodities. Some of the commodities like Agri Commodities are open from 9 am to 9 pm. In contrast, other commodities, including bullion, crude oil, metals, and internationally linked Agri commodities are closed much later, around 11.55 pm.</p>',
    '<p>The Security and Exchange Board of India (SEBI) extended the timings for commodity trading, as it could invite more participants along with deepening the commodity markets.</p>',
    '<h6>How can commodity trading be beneficial?</h6>',
    '<p>The benefits of commodity trading include:</p>',
    '<ul><li>1) Hedging: In commodity trading, the trading of a particular commodity is done on a pre-decided price. So the price fluctuations don’t affect the agreement.</li><li>2) Speculation: Traders can make considerable profits in commodity trading by speculating the price changes of the future.</li><li>Arbitraging: Arbitraging is done when the price of a particular commodity differs from market to market. In such situations, traders buy the commodities at low prices and instantly sell them at a much higher price.</li></ul>',
    '<p>These benefits allow the trader to make sufficient profit through commodity trading. If commodity trading is done very smartly and carefully, it can help you make huge money.</p>',
    '<h6>How to select a broker for Commodity Trading?</h6>',
    '<p>The selection of a commodity broker is one of the most challenging parts of commodity trading. The choice of the right broker is very vital as your trading entirely depends on it. So the selection must be made very cautiously, considering a variety of factors. While selecting a broker, you must carefully analyze the broker’s credibility and must select only certified brokers. You must also check whether the broker provides the facility of commodity trading online as your whole trading experience depends on the services offered by the broker. The brokerage price charged by the broker is another crucial factor to be considered before selecting a broker.</p>',
    '<p>You must take the demo of the service before selecting the right broker to ensure the quality of services provided by the broker. If the trader offers commodity trading online, you must first check the online trading platform and understand the working of the platform entirely before investing. Before investing, you must gain adequate information about the money deposit along with the whole trading mechanism followed by the trader. The proficiency of services, accessibility, and customer support team must be cross-checked before selecting the broker. You must ensure the strength and proactiveness of the customer support team, as it will be assisting you during any queries or issues.</p>',
    '<h6>How to open a commodity trading account?</h6>',
    '<p>After selecting the right broker for your trading, the next step for trading would be to open a commodity trading account. This account is the primary requirement for commodity trading. In order to open a commodity trading account, you need to submit an application form along with all the necessary documents like your id proof, financial statement, and source of income. This application form will then be either accepted or rejected by the broker, depending upon your financial and other details. This step is crucial as it allows the broker to make informed decisions about whether he should be making an agreement with this particular trader or not. This decision judges his future profit or loss to a great extent.</p>',
    '<h6>What is the minimum amount required for investment?</h6>',
    '<p>After making an account, the trader will have to invest a particular sum of money as the initial amount in order to start his trading. This deposit money must be at least 5 – 10% of the total contract value. Along with this initial deposit, the trader also needs to pay a maintenance amount to the broker. This maintenance amount covers all the losses that might occur during adverse market conditions.</p>',
    '<h6>Importance of Trading Plan</h6>',
    '<p>A trading plan or strategy plays a vital role in commodity trading. You can make the trading highly profitable by adopting the right trading strategy. This trading plan depends on the financial goals and risk-bearing capability, so you must make the plan as per them. The trading plan differs from trader to trader as what is suitable for one might not be for another. You must conduct in-depth research before mapping the adequate trading strategy.</p>',
    '<p>All in all, the result of commodity trading depends entirely on the traders and brokers. So if the trader is aiming towards profit, he must act very smart and judge spontaneously. The whole profit depends on the trading skills of brokers, proper understanding of the entire trading process, and selection of appropriate brokers. You must also be able to know where to put a stop and must never be lured by the profits. The trader must also adopt various risk management techniques to minimize the risks and maximize the returns.</p>',
  ],
};
export const mcxOptons = {
  title: 'MCX Options Tips for Consistently Better Returns',
  body: [
    '<p>Multi Commodity Exchange India is a multi-commodity exchange that provides trading services for agricultural products, minerals, metals, energy, and industrial products.</p>',
    "<p>Multi Commodity Exchange, or MCX, is headquartered in Mumbai and is the country's leading commodity exchange. It provides trading services for agricultural products, minerals, metals, energy, and industrial products. It offers a range of services, including spot trading of commodities and futures contracts on commodities like crude oil, natural gas, gold, and silver.</p>",
    '<h6>What are MCX Options?</h6>',
    '<p>MCX options are contracts that provide investors & traders with the opportunity to buy or sell commodity futures at a later time. These non-securities give investors the right to purchase or sell the physical commodity. The options provide certain rights to the buyer, which in this case is the ability to buy the commodity at a specific price.</p>',
    '<p>The buyers have to pay a premium for the call option, but they can earn significantly if the asset price moves in the desired direction. The sellers, honestly, are at much greater risk, as the buyer has the right to buy the asset but not the obligation. They can exercise their right if they want to.</p>',
    '<p>That is the gist is what MCX Options are. And you can trade in the commodity market using the MCX Option Tips provided by WayonC. We offer MCX Options Tips packages for small and medium traders and the packages include Crude oil options tips, natural gas options tips, gold option tips, and silver options tips.</p>',
    '<h6>Who should subscribe to our service?</h6>',
    '<p>Our Options Tips service is intended for traders with a trading lot capacity of 1 or 2 lots. Whether you are a beginner in trading or a veteran with years of experience trading commodities, you can benefit from the WayonCOptions Tips service.</p>',
    '<h6>Service Features:</h6>',
    '<ul><li>Subscribers will receive 1 to 2 Options Tips every day</li><li>On average, subscribers will receive 20 to 22 Options Tips in a month</li><li>Guaranteed 1% return on investment</li><li>The Options tips are provided via Web Login or the app</li><li>Executive support is available to our subscribers</li></ul>',
    '<h6>Included in the MCX Option Tips</h6>',
    '<ul><li>Crude Oil Options Tips</li><li>Natural Gas Options Tips</li><li>Gold Options Tips</li><li>Silver Options Tips</li></ul>',
    '<h6>MCX Options Advisory Company</h6>',
    '<h6>Consistent Profit with Option Tips</h6>',
    '<p>Commodities are traded on the global market. It is traded in the form of futures contracts and options. The option tips can be used to gain consistent profit. With the right moves and understanding of the movement of the market, a trader can be handsomely rewarded for all their efforts.</p>',
    '<p>The Option tips are generated with in-depth market analysis by professionals, researchers, and analysts who have been doing this for several years. Traders need to lean on their Option Tips and make the best of the opportunity provided to them.</p>',
    '<h6>Advantages of trading in Options</h6>',
    '<p>Options are a type of derivative that allows traders to speculate on the price of any selected commodity. These contracts are traded on the MCX exchange. Trading in commodities options can be done via an exchange or through a broker who sells these contracts.</p>',
    '<h6>The advantages of trading in options include the following:</h6>',
    '<p>The price of a commodity can be predicted with accuracy, which makes it easier for traders to make money by buying and selling options at different times throughout the day.</p>',
    '<p> Trading options can be done from anywhere in the world, making it easy for traders to trade while they are on vacation or traveling abroad.</p>',
    '<p>Trading options do not require any special knowledge or experience, making it easy for anyone to trade them without having to worry about losing money due to a lack of knowledge about how they work.</p>',
    "<p>No commissions are involved when trading these options, meaning the trader doesn't have to pay anything out of pocket.</p>",
    '<p> One can trade these options without having to worry about margin requirements or other restrictions that come with different types of derivatives like futures and stocks.</p>',
    '<h6>What are the different types of Commodity Options in MCX?</h6>',
    '<p>Investors who wish to manage their risk and increase their flexibility might want to purchase Options.</p>',
    "<p>Options are divided into two classes – Calls and Puts. Traders may purchase call options for the same assets, but when they're bearish about the asset prices, they may buy put options instead.</p>",
    '<p>Buying calls & puts are not the only way to trade options. Selling them is also a popular strategy among many professional option traders. More complex option trading strategies, such as spreads, can also be constructed by simultaneously buying different options and selling them at different prices.</p>',
    '<h6>How to know which Option tips are trustworthy and provide the best outcomes?</h6>',
    '<p>The commodity market is filled with several trader tips service providers, but not all of them are trustworthy. It is crucial for one to find a credible MCX Option Tips advisory that can provide them with genuine MCX Options tips and ensure a substantial profit in a short time. WayonCis one such MCX Option Tips provider</p>',
    '<p>Not only do we inform our clients with the correct calls and puts, but we will provide them with crucial information and strategies that will enable them to get the best result from the market.</p>',
    '<p>MCX Option Tips with WayonC</p>',
    '<p>WayonCwill teach you a variety of different MCX Option strategies that can maximize your investments for the best results. With more than a decade of experience and training, our experts can help you use those strategies based on your needs</p>',
    '<p>Additionally, these tips will help you make a substantial return on your profits. With our MCX Option tips, you will be able to understand the market so that you can create opportunities for yourself. With our guidance, you can grow your earnings fast</p>',
    '<h6>Why MCX Options Tips are a great idea?</h6>',
    '<p>MCX options are a good way for investors to hedge against commodity price fluctuations. The benefits of MCX Options include low cost and flexibility. These benefits make them an attractive investment option for individuals and businesses alike.</p>',
    '<p>If you are looking for a way to hedge against price fluctuations, then you should consider investing in these options. And Options Tips can help traders with just that.</p>',
    '<p>Options tips are a type of trading advice that is given to an investor. They are meant to help the investor make better decisions in the market. These options tips are mainly used by professional traders who have knowledge of the stock market. They can also be used by beginners who want to learn how to trade and make more informed decisions.</p>',
    '<p>The benefits of MCX Options tips include:</p>',
    "<ul><li>Get a consistent profit from your investment.</li><li>Having a better understanding of how the markets work and what investors should do in order to increase their odds of success in the market</li><li>Learn about different types of markets</li><li>Get access to a variety of different trading strategies that can help you achieve your goals with less risk.</li><li>Using a variety of indicators to make better trading decisions</li><li>Trying different types of investments</li><li>Tracking your performance to see what works and what doesn't</li></ul>",
    '<h6>What are the benefits of Option Tips from WayonC?</h6>',
    '<p>There is a misconception that people can trade on their own and many do. However, trading requires knowledge and experience to make the most out of all the information available in the market. To fully comprehend this information and benefit from them, one needs to know which type of market they are dealing with before even thinking about taking action. Certain things like market trends and time are required in order to make a sound investment decision. It is why we highly recommend that you spend some time looking for MCX Option Tips.</p>',
    '<p>At WayonC, we want to help you along your trading journey. We share our expert tips on Options to teach you about the sector. Our Commodity Options tips will help guide your trade and keep you updated with all of the latest information. For the best Options, there is no better partner that you can connect with than us.</p>',
    '<h6>Difference between a Future Contract and an Option</h6>',
    "<p>The holder of an Options contract has the right to buy or sell an underlying asset at a specified price on a certain date but not the obligation to do so. Or, to put it another way, it's a contract to buy and sell options. The holder can exercise their option by buying or selling the underlying asset at its agreed-upon price. They can also back out of it without any issue.</p>",
    '<p>A futures contract is similar to an options contract in that it gives the holder the right to buy or sell the commodity. However, unlike options contracts, futures contracts are traded on exchanges rather than over-the-counter (OTC).</p>',
    '<h6>How to Buy a New Option Contract?</h6>',
    '<p>Buying a new option contract is not as easy as buying a stock or bond, but it can be done.</p>',
    "<p>When an investor buys an option contract, they are purchasing the right to buy or sell a defined amount of shares at a set price on or before the contract's expiration. The investor pays the option seller an amount of money called the premium in exchange for that right. The seller then has to decide whether they want to exercise their rights and sell those shares at that price or let them expire worthless.</p>",
    '<p>The entry point for an Option contract is pretty low per share because this is where most investors start their trades when they first begin investing in options.</p>',
    '<p>What is the best indicator for trading commodities?</p>',
    '<p>Commodity trading is a complex and risky market. It is crucial for traders to have the right indicators to help them make the best decisions.</p>',
    '<p>Different indicators can be used for trading. Some of these indicators are price, volume, and volatility. The most popular indicator is the price-volume indicator which uses the relationship between the price and volume of the commodity traded on a given day to predict future prices.</p>',
    '<p>When it comes to energy and, more specifically, Natural Gas, many traders observe the Henry Hub Natural Gas Spot Price, the Henry Hub Natural Gas Futures Price, and the NYMEX Natural Gas Futures Price. These are widely considered the best indicator for Natural Gas trading.</p>',
    '<h6>What is a Natural Gas index?</h6>',
    '<p>The Natural Gas Index is a measure of the price of natural gas in US dollars.</p>',
    '<p>The index is calculated by multiplying the spot price for natural gas by a conversion factor that takes into account the current exchange rate. The index is then divided by a conversion factor that considers the cost of living in US dollars.</p>',
    '<p>The Natural Gas Index is used to assess changes in the value of natural gas relative to other commodities and currencies. Future Contract and Options Traders keep a keen eye on the Natural Gas Index (NGX). This index can be used as a benchmark for Natural Gas Options Trading.</p>',
    '<p>Reach out to us at 0281 619 9999 or fill out the inquiry form to learn more about the MCX Option tips from WayonC.</p>',
  ],
};
export const refundPolicy = {
  title: 'Refund Policy',
  body: [
    '<p>Customer satisfaction is important to us at WayonC– Investment Advisor.</p>',
    '<p>All services offered by WayonClimited is subject to the terms and conditions and clauses mentioned in agreement signed in between client and WayonC-Investment Adviser.</p>',
    '<p>Incase of Amendment or termination of agreement, if the amendments are acceptable to both the parties the services would be continued as agreed. In case of termination of agreement by client or by adviser, we would refund 50% fees for the unexpired period and 50% fees would be charged as breakage fee, however a maximum breakage fee that would be levied would not be more than one quarter. In case of termination of contract we would guide client for exiting his investment which he has done as per our advise.</p>',
    '<p>All the recommendations/advice are given to you based on our judgments and the information available on particular point of time. However Directors, Technicians or employees will not be responsible for the losses or gains made through the calls. Clients are advised to exercise the tips at their own risk.</p>',
    '<p>If you have any questions about our service please, email us at investors@WayonC.com or call us on 9512848969.</p>',
    '<p>All disputes will Be subject to jurisdiction of Rajkot only.</p>',
  ],
};
export const disclaimer = {
  title: 'Disclaimer',
  body: [
    '<p>WayonC– Investment Advisor Limited, its directors, servants and/or agents shall not, be responsible or liable for the economic performance of your portfolio or for any losses, claims, costs or expenses as a result of the performance of our obligations to you an advisory client and for any losses / damages suffered due to the market fluctuation.</p>',
    '<p>M/s. WayonC– Investment Advisor Ltd or its related companies or their directors or employees do not give any warranty, make any representation as to, or accept responsibility for, its accuracy, reliability, timelines or completeness now or in the future. While the information provided by M/s. WayonCLtd is believed to be accurate, WayonCdoes not accept responsibility for any inaccuracy or any actions taken upon reliance with the information on this site.</p>',
    '<p>WayonC– Investment Advisor reserves the right to make modifications and alterations in the material contained at this site without any intimation to you of such modifications.</p>',
    '<p>All the tips/Advice given to you are based on our Research, judgments and the information available on particular point of time. However directors, employees or the technicians of the site will not be responsible for the losses or gains made through the calls. Members are advised to exercise the tips at their own risk. We shall also not be responsible for failure of connectivity of network and internet for any reasons, whatsoever.</p>',
    '<p>All views and recommendation are applicable till the stop loss is not violated. The view communicated or recommended will be not be valid if the stop loss is violated.</p>',
    '<p>WayonC– Investment Advisor does not provide assured return in any of its services; Investors are advised to beware of such fraudulent calls.</p>',
    '<p>Any surfing and reading of the information is the acceptance of this disclaimer. All Rights Reserved.</p>',
    '<p>This agreement shall be subject to the jurisdiction of the Rajkot.</p>',
  ],
};
export const privacyPolicy = {
  title: 'Privacy Policy',
  body: [
    '<p>At WayonC– Investment Advisor we respect your privacy. To better protect your privacy we provide this notice explaining our online information practices and the choices you can make about the way your information is collected and used.</p>',
    '<p>We gather certain information that is provided by you to the Web Site. We believe that by collecting this information We will be able to provide you the best of our services to assist our own team in efficiently reaching an appropriate audience.</p>',
    '<p>The privacy and protection of your data and information provided to us is of vital importance. We shall ensure to safeguard the security and confidentiality of any information you share with us. Any personally identifiable information of the customers obtained by us shall not be used or shared other than for the purposes to which the customers consents. However security and confidentiality of information cannot be guaranteed cent percent. Hence despite our utmost efforts to protect your personal information, we cannot warrant the security of any information you transmit to us through our online services. Such transmission of your personal information is done at your own risk.</p>',
    '<p>In addition to the service provided to you, your information (mobile number, E-mail ID etc.) can be brought in use for sending you newsletters, surveys, contest information, or information about any new services of the company which will be for your benefit and while subscribing for our services, you agree that WayonC– Investment Advisor has the right to do so.</p>',
    "<p>By filling out the 'Inquiry form' on this website, you agree to provide us your valid mobile number. Also by providing your mobile number, you provide us the consent to call you on the number provided by you and send SMS on your mobile number. You provide your consent for us to call / SMS you on your mobile number even if it is registered on the National 'Do Not Disturb' registry.</p>",
  ],
};

export const termsAndConditions = {
  title: 'Term & Conditions',
  body: [
    '<p>This Website is designed, developed and maintained by WayonC– Investment Advisor.</p>',
    '<p> By ordering our services you are agreeing to these Terms & Conditions and Disclaimer.</p>',
    '<p> All our clients will have to complete Risk profiling, KYC formalities and will have to sign an agreement with us before receiving our services. Services will only get started after you complete all our compliance formalities.</p>',
    '<p>Handling complaints: If you have any complaints regarding Services received from us, please send an email to investors@WayonC.com or call on : +919512848969 detailing the nature of your complaint. We will acknowledge the complaint within 48 hours and provide a likely timescale for resolving the dispute while keeping you informed about progress. All complaints will be considered highly confidential, and be dealt with as soon as is possible.</p>',
    '<p>Our clients, Any third party or anyone else have no rights to forward or share our calls or SMS or Report or Any Information provided by us to / with anyone which is received directly or indirectly by them. If found so then Legal Actions can be taken.</p>',
    '<p>Unauthorized attempts to upload information, change or delete information on this website are strictly prohibited and punishable under Indian IT Act.</p>',
    '<p>These Terms & Conditions shall be governed and construed in accordance with the Indian Laws and any disputes arising under these Terms & Conditions shall be subject to the Jurisdiction of the Rajkot only.</p>',
    '<p>All the transactions shall be completed at Rajkot only.</p>',
    '<p>On filling and submitting any form in WayonCwebsite, you will authorize an Executive of WayonCto call you even if your number is registered under Do Not Call registration.</p>',
    '<p>WayonC– Investment Advisor may revise the Terms of Use at any time without notice.</p>',
  ],
};
