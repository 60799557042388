import React from 'react'
import {BrowserRouter as Router,Routes,Route} from 'react-router-dom';
import './assets/main.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import Insurance from './pages/home/insurance'
import AboutUsV1 from './pages/about-us';
import SubServices from './pages/services/SubServices';
import Cash from './pages/services/equity/Cash';
import Delivery from './pages/services/equity/Delivery';
import Future from './pages/services/equity/Future';
import Index from './pages/services/equity/Index';
import Option from './pages/services/equity/Option';
import StockIndex from './pages/services/equity/StockIndex';
import Bullions from './pages/services/mcx/Bullions';
import Energy from './pages/services//mcx/Energy';
import McxOption from './pages/services/mcx/McxOption';
import Metal from './pages/services/mcx/Metal';
import NormalMcx from './pages/services/mcx/NormalMcx';
import Ncdex from './pages/services/ncdex/Ncdex';
import Contact from './pages/Contact'
import Login from './pages/admin/Login';
import LogInForm from './pages/LoginForm';
import ClientForm from './pages/form/ClientForm';
import ClentID from './pages/admin/Dashboard';
import ShowMore from './pages/admin/ShowMore';
import FormDetails from './pages/admin/formdetail/FormDetails';
import ClientsFormData from './pages/admin/receivedforms';
import TodayEarner from './pages/admin/TodayEarners';
import RequestsData from './pages/admin/Requests';
import BackupData from './pages/admin/BackupData';
import AddUserData from './pages/admin/AddUserData';
import AgreementPage from './pages/admin/agreements/AgreementPage';
import AdminProfile from './pages/admin/AdminProfile';
import ClientsData from './pages/admin/ClientsData';
import CheckOutShowMore from './pages/admin/checkout/CheckOutShowMore';
// import ClientDashboard from './pages/clientDashbord/ClientDashboard';
import BackupDataDetails from './pages/admin/backupSM/BackupDataDetails';
import ClientInvestment from './pages/clientDashbord/invest/index';
import AgreementDetails from './pages/admin/agreements/AgreementDetails';
import AgreementDetailsShoeMore from './pages/admin/agreements/details/AgreementDetailsShowMore';
import ClientDashboard from './pages/clientDashbord';
import ClientProfile from './pages/clientDashbord/profile';
import IntradayTrading from './pages/IntradayTrading';
import ShareMarkett from './pages/ShareMarket';
import Coommodity from './pages/Commodity';
import ShareTrading from './pages/ShareTrading';
import StockMarket from './pages/StockMarket';
import McxOptions from './pages/McxOptions';
import RefundPolicy from './pages/RefundPolicy';
import Disclaimer from './pages/Disclaimer';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions';
import Blogs from './pages/blog';
import InvestorCharter from './pages/InvestorChartor';
import Testimonials from './pages/Testimonials';
import ForgetPassword from './pages/ForgetPassword';
// import ClientProfile from './pages/clientDashbord/profile/ClientProfile';
import UserLogin from './pages/user/Login';
import UserDashboard from './pages/user/index';
import UserRecievedForms from './pages/user/receivedforms';
import UserFormDetails from './pages/user/formdetail/UserFormDetails';
import UserAgreementPage from './pages/user/agreements/UserAgreement';
import UserAgreementPageDetails from './pages/user/agreements/UserAgreementPageDetails';
import AgreementPageDet from './pages/user/agreements/details/AgreementPageDet';

const App = () => {
  return (
    <div>
<Router>
  <Routes>
    <Route path='/' exact element={<Insurance/>}/>
    <Route path='/about-us' exact element={<AboutUsV1/>}/>
    <Route path='/sub-services' exact element={<SubServices/>}/>
    <Route path='/services/equity/intraday' exact element={<Cash/>}/>
    <Route path='/services/equity/swing-trading' exact element={<Delivery/>}/>
    <Route path='/services/equity/sip' exact element={<Future/>}/>
    <Route path='/services/equity/one-time-lumbsum-investment' exact element={<Index/>}/>
    <Route path='/services/future-and-options/stock-future' exact element={<Bullions/>}/>
    <Route path='/services/future-and-options/index-future' exact element={<Energy/>}/>
    <Route path='/services/future-and-options/stock-option' exact element={<McxOption/>}/>
    <Route path='/services/future-and-options/index-option' exact element={<Metal/>}/>
    <Route path='/services/ncdex/ncdex' exact element={<Ncdex/>}/>
    <Route path='/login' exact element={<LogInForm/>}/>
    <Route path='/forget-password'exact element={<ForgetPassword/>}/>
    <Route path='/contact-us' exact element={<Contact/>}/>
    
    <Route path='/intraday-trading' exact element={<IntradayTrading/>}/>
    <Route path='/share-market' exact element={<ShareMarkett/>}/>
    <Route path='/commodity' exact element={<Coommodity/>}/>
    <Route path='/share-trading' exact element={<StockMarket/>}/>
    <Route path='/stock-market' exact element={<McxOptions/>}/>
    <Route path='/mcx-options' exact element={<McxOptions/>}/>
    <Route path='/commodity' exact element={<Coommodity/>}/>
    <Route path='/refund-policy' exact element={<RefundPolicy/>}/>
    <Route path='/disclaimer' exact element={<Disclaimer/>}/>
    <Route path='/privay-policy' exact element={<PrivacyPolicy/>}/>
    <Route path='/terms-and-conditions' exact element={<TermsAndConditions/>}/>
    <Route path='/blogs' exact element={<Blogs/>}/>
    <Route path='/investor-chartor' exact element={<InvestorCharter/>}/>
    <Route path='/testimonals' exact element={<Testimonials/>}/>





    {/* dashboard routes */}
    <Route path='/admin/login' exact element={<Login/>}/>
    <Route path='/form/client-form' exact element={<ClientForm/>}/>
    <Route path='/admin/dashboard' exact element={<ClentID/>}/>
    <Route path='/admin/:id' exact element={<ShowMore/>}/>
<Route path='/admin/receivedforms/' exact element={<ClientsFormData/>}/>
<Route path='/admin/receivedforms/:id' exact element={<FormDetails/>}/>
<Route path='/admin/clientsData/' exact element={<ClientsData/>}/>
<Route path='/admin/todayEarners/' exact element={<TodayEarner/>}/>
<Route path='/admin/checkout/:id' exact element={<CheckOutShowMore/>}/>
<Route path='/admin/requests/' exact element={<RequestsData/>}/>
{/* <Route path='/admin/requests/:id' exact element={<CheckOutShowMore/>}/> */}
<Route path='/admin/backups/' exact element={<BackupData/>}/>
<Route path='/admin/backupSM/:id/' exact element={<BackupDataDetails/>}/>
<Route path='/admin/addUser/' exact element={<AddUserData/>}/>
<Route path='/admin/agreements/' exact element={<AgreementPage/>}/>
<Route path='/admin/agreements/:id' exact element={<AgreementDetails/>}/>
<Route path='/admin/agreements/details/:id' exact element={<AgreementDetailsShoeMore/>}/>
<Route path='/admin/adminProfile/' exact element={<AdminProfile/>}/>



{/* client routes */}
{/*
<Route path='/clientDashboard/:id/profile' exact element={<ClientProfile/>}/> */}


<Route path='/clientDashboard/:id' exact element={<ClientDashboard/>}/>
 {/* <Route path='/clientDashboard/dashboard/:id' exact element={<ClientDashboard/>}/> */}
<Route path='/clientDashboard/:id/invest' exact element={<ClientInvestment/>}/>
<Route path='/clientDashboard/:id/profile' exact element={<ClientProfile/>}/>

{/* user routes */}


<Route path='/user/login' exact element={<UserLogin/>}/>
<Route path='/user' exact element={<UserDashboard/>}/>
<Route path='/user/receivedforms' exact element={<UserRecievedForms/>}/>
<Route path='/user/formdetail/:id' exact element={<UserFormDetails/>}/>
<Route path='/user/agreements' exact element={<UserAgreementPage/>}/>
<Route path='/user/agreements/:id' exact element={<UserAgreementPageDetails/>}/>
<Route path='/user/agreements/details/:id' exact element={<AgreementPageDet/>}/>

  </Routes>
</Router>
    </div>
  )
}

export default App