export const CashData = {
    title: "Cash",
    cate: "equity",
    description: "description",
    mainImage: "images/icon/icon_104.svg",
    pageUrl: "Cash",
    cashData: [
        {
            "cashDataInfo": {
                "id": "1",
                "title": "Intraday Calls",
                "body": [
                    "<p>WHO SHOULD SUBSCRIBE THIS SERVICE?</p>",
                    "<p>If your Intraday trading volume is between 1 lac to 3 lac, then you should definitely subscribe this service.</p>",
                    "<h4>SERVICES FEATURE</h4>",
                    "<ul><li>	We will provide 1-2 Cash Intraday Tips every day.</li><li>	We will provide 20-22 Cash intraday trading tips in a month</li><li>	Approx 1% return per call</li><li>	Trading tips given via Web Login / App.</li><li>Our Executives will call after-market hours for trade verification and support.</li></ul>",
                    "<h4>INTRADAY TIPS SAMPLE CALLS:</h4>",
                    "<h4>(INTRADAY CALL) BUY ADANIENT ABOVE 160 TGT 161.50, 163 SL 158 (NSE CASH)</h4>",
                    "<p>FOLLOW UP CALLS :(INTRADAY CALL) OUR 1ST TGT ACHIVED IN ADANIENT AT 161.50. OUR BUY CALL GIVEN AT 160.(INTRADAY CALL) OUR ALL TGT ACHIVED IN ADANIENT AT 163. OUR BUY CALL GIVEN AT 160.</p>"
                ]
            },
            "tableData": {
                "title": "Intraday Cash",
                "tableDataList": [
                    {
                        "id": "1",
                        "title": "PERIOD",
                        "data1": "2 Days",
                        "data2": "7 Days",
                        "data3": "15 Days",
                        "data4": "1 Months",
                        "data5": "3 Months",
                        "data6": "6 Months"
                    },
                    {
                        "id": "1",
                        "title": "AMOUNT",
                        "data1": "one",
                        "data2": "one",
                        "data3": "one",
                        "data4": "one",
                        "data5": "one",
                        "data6": "one"
                    },
                    {
                        "id": "1",
                        "title": "GST  (18%)",
                        "data1": "one",
                        "data2": "one",
                        "data3": "one",
                        "data4": "one",
                        "data5": "one",
                        "data6": "one"
                    },
                    {
                        "id": "1",
                        "title": "TOTAL",
                        "data1": "one",
                        "data2": "one",
                        "data3": "one",
                        "data4": "one",
                        "data5": "one",
                        "data6": "one"
                    }
                ]
            }
        }
      
    ],
    serviceCost:
      "Service charges are excluding taxes. 18% GST will be charged on the package amount.",
  };
  export const OptionData = {
    title: "Option",
    cate: "Cash",
    subCate: "Cash",
    description: "description",
    mainImage: "mainImage",
    pageUrl: "Option",
    cashData: [
      {
        cashDataInfo: {
          id: "1",
          title: "Option",
          body: [
            "<p>This package is suitable for the conservative trader, who look to trade in Stock Options with low risk. Option Trading Tips – We provide customized option strategies in stock options.</p>",
            "<h6  >WHO SHOULD SUBSCRIBE THIS SERVICE?</h6>",
            "<p>If your stock option trading capacity is of 1-3 lots, then you should definitely subscribe this service.</p>",
            "<h6  >SERVICES FEATURE</h6>",
            "<ul><li>We will provide 1-2 Option tips every day.</li><li>We will provide 20-22 Option tips in a month</li><li>Approx 10-15% return per call</li><li>Trading calls given via Web Login / App.</li><li>Our Executives will call after market hours for trade verification and support.</li></ul>",
            "<h6  >SAMPLE CALLS :</h6>",
            "<p>(OPTION) BUY ADANIENT 160 CALL ABOVE 10 TGT 11.50, 13 SL 8 (NSE CASH)</p>",
            "<h6  >FOLLOW UP CALLS :</h6>",
            "<p>(OPTION) OUR 1ST TGT ACHIVED IN ADANIENT 160 CALL AT 11.50. OUR BUY CALL GIVEN AT 10.</p>",
            "<p>(OPTION) OUR ALL TGT ACHIVED IN ADANIENT 160 CALL AT 13. OUR BUY CALL GIVEN AT 10.</p>",
          ],
        },
        tableData: {
          title: "NORMAL OPTION",
          tableDataList: [
            {
              id: "1",
              data1: "2 Days",
              data2: "1,500",
              data3: "270",
              data4: "1,770",
            },
            {
              id: "2",
              data1: "7 Days	",
              data2: "2,500",
              data3: "450",
              data4: "2,950",
            },
            {
              id: "3",
              data1: "15 Days	",
              data2: "4,000",
              data3: "720",
              data4: "4,720",
            },
            {
              id: "4",
              data1: "1 Month	",
              data2: "7,000",
              data3: "1,260",
              data4: "8,260",
            },
            {
              id: "5",
              data1: "3 Months	",
              data2: "18,000",
              data3: "3,240",
              data4: "21,240",
            },
            {
              id: "6",
              data1: "6 Months	",
              data2: "32,000",
              data3: "5,760",
              data4: "37,760",
            },
          ],
        },
      },
      {
        cashDataInfo: {
          id: "2",
          title: "Premium Option",
          body: [
            "<p>This package is suitable for HNI and risk taking traders who trades only in Stock options tips.</p>",
            "<h6  >WHO SHOULD SUBSCRIBE THIS SERVICE?</h6>",
            "<p>If your stock option trading capacity is between 3 lots to 7 lots, then you should definitely subscribe this service.</p>",
            "<h6  >SERVICES FEATURES</h6>",
            "<ul><li>We will provide 1-2 Premium Stock Options tips every day.</li><li>We will provide 1-2 Premium Stock Options tips every day.</li><li>We will provide 20-22 Premium Stock Options tips in a month</li><li>Approx 10-30% return per call</li><li>Important global market Updates</li><li>Important Updates of all the likely stocks in the News</li><li>Trading calls given via Web Login / App. and any other support through phone call.</li><li>Timely Entry and Exit</li><li>Blend of personalized & customized services offered to meet all your investment needs.</li></ul>",
            "<h6  >SAMPLE CALLS :</h6>",
            "<p>(PREMIUM OPTION) BUY AUROPHARMA 800 CALL ABOVE 20 TGT 24, 28 SL 16</p>",
            "<h6  >FOLLOW UP CALLS :</h6>",
            "<p>(PREMIUM OPTION) OUR 1ST TGT ACHIVED IN AUROPHARMA AT 24. OUR BUY CALL GIVEN AT 20.</p>",
            "<p>(PREMIUM OPTION) OUR ALL TGT ACHIVED IN AUROPHARMA AT 28. OUR BUY CALL GIVEN AT 20.</p>",
          ],
        },
        tableData: {
          title: "PREMIUM OPTION",
          tableDataList: [
            {
              id: "1",
              data1: "2 Days	",
              data2: "5,000",
              data3: "900",
              data4: "5,900",
            },
            {
              id: "2",
              data1: "7 Days	",
              data2: "9,000",
              data3: "1,620",
              data4: "10,620",
            },
            {
              id: "3",
              data1: "15 Days	",
              data2: "17,000",
              data3: "3,060",
              data4: "20,060",
            },
            {
              id: "4",
              data1: "1 Months	",
              data2: "30,000",
              data3: "5,400",
              data4: "35,400",
            },
            {
              id: "5",
              data1: "3 Months	",
              data2: "80,000",
              data3: "14,400",
              data4: "94,400",
            },
            {
              id: "6",
              data1: "6 Months	",
              data2: "1,25,000",
              data3: "22,500",
              data4: "1,47,500",
            },
          ],
        },
      },
      {
        cashDataInfo: {
          id: "3",
          title: "HNI Option",
          body: [
            "<p>This service is exclusively designed for HNI clients, who wants to get higher returns compared to regular services. We take utmost care while providing calls, profit booking and timely exiting.</p>",
            "<h6  >WHO SHOULD SUBSCRIBE THIS SERVICE?</h6>",
            "<p>If your stock option trading capacity is of 10 lots or above, then you should definitely subscribe this service.</p>",
            "<h6  >SERVICES FEATURE</h6>",
            "<ul><li>We will provide you 4-5 HNI Stock Options tips in a month</li><li>We will provide 1-2 Premium Stock Options tips every day.</li><li>Approx 10-30% return per call</li><li>Important global market Updates</li><li>Important Updates of all the likely stocks in the News</li><li>Trading calls given via Web Login / App. and any other support through phone call.</li><li>Timely Entry and Exit</li><li>Blend of personalized & customized services offered to meet all your investment needs.</li></ul>",
            "<h6  >SAMPLE CALLS :</h6>",
            "<p>(HNI OPTION) BUY RELIANCE 1100 CALL ABOVE 25 TGT 30, 35 SL 20</p>",
            "<h6  >FOLLOW UP CALLS :</h6>",
            "<p>(HNI OPTION) OUR 1ST TGT ACHIVED IN RELIANCE 1100 CALL AT 30. OUR BUY CALL GIVEN AT 25</p>",
            "<p>(HNI OPTION) OUR ALL TGT ACHIVED IN RELIANCE AT 35. OUR BUY CALL GIVEN AT 25.</p>",
          ],
        },
        tableData: {
          title: "Bonanza Cash",
          tableDataList: [
            {
              id: "1",
              data1: "2 Days	",
              data2: "15,000",
              data3: "2,700",
              data4: "17,700",
            },
            {
              id: "5",
              data1: "7 Days	",
              data2: "20,000",
              data3: "3,600",
              data4: "23,600",
            },
            {
              id: "3",
              data1: "15 Days	",
              data2: "37,500",
              data3: "6,750",
              data4: "44,250",
            },
            {
              id: "4",
              data1: "1 Months	",
              data2: "70,000",
              data3: "12,600",
              data4: "82,600",
            },
            {
              id: "5",
              data1: "3 Months	",
              data2: "1,25,000",
              data3: "22,500",
              data4: "1,47,500",
            },
          ],
        },
      },
    ],
    serviceCost:
      "Service charges are excluding taxes. 18% GST will be charged on the package amount.",
  };
  
  export const DeliveryData = {
    title: "SWING TRADING",
    cate: "equity",
    description: "description",
    mainImage: "mainImage",
    pageUrl: "Delivery",
    cashData: [
        {
            "cashDataInfo": {
                "id": "1",
                "title": "	SWING TRADE",
                "body": [
                    "<p>This package is suitable for short term positional traders, who want to gain more by holding suggested stock.</p>",
                    "<ul><li>	2-4 calls per month</li><li>	1-6 months’ average holding period per call</li><li>	Calls through Web Login / App.</li><li>	20-50% average return per call</li><li>	Assistance in your existing portfolio to minimize losses</li><li>	Invest not more than 5% of your capital in a call</li></ul>",
                  
                ]
            },
            "tableData": {
                "title": "Swing Trade",
                "tableDataList": [
                    {
                        "id": "1",
                        "title": "STOCK NAME",
                        "data1": "McDOWELL - N",
                        "data2": "750",
                        "data3": "5TH APRIL",
                        "data4": "900 (20%)",
                        "data5": "14TH JUNE",
                      
                    },
                    {
                        "id": "2",
                        "title": "BUY PRICE",
                        "data1": "NAZARA",
                        "data2": "560",
                        "data3": "2ND MAY",
                        "data4": "700 (25%)",
                        "data5": "13TH JUNE",
                    }
                ]
            }
        }
    ],
    serviceCost:
      "Service charges are excluding taxes. 18% GST will be charged on the package amount.",
  };
  
  export const indexData = {
          "title": "	ONE TIME LUMPSUM INVESTMENT",
          "cate": "equity",
          "description": "description",
          "mainImage": "mainImage",
          "pageUrl": "Index",
          "cashData": [
            {
            "cashDataInfo": {
                "id": "1",
                "title": "ONE TIME LUMPSUM INVESTMENT",
                "body": [
                    "<p>This package is suitable for those who want to do targeted investment in a lump sum amount in multiple stocks in one go and gain good returns holding suggested stock for long term i.e. 1 year or more. It is a tailor made suggestion to suit every individual investor according to their own investing and risk to reward appetite</p>",
                    "<ul><li>	Multiple numbers of stocks will be suggested depending on the amount to be invested and the expectations of the investor.</li><li>	1 year or more average holding period per entry</li><li>	Calls through Web Login / App.</li><li>	20-60% average return per call</li><li>	Assistance in your existing portfolio to minimize losses</li></ul>",

                ]
            },
            "tableData": {
                "title": "Intraday Cash",
                "tableDataList": [
                    {
                        "id": "1",
                        "title": "PERIOD",
                        "data1": "2 Days",
                        "data2": "7 Days",
                        "data3": "15 Days",
                        "data4": "1 Months",
                        "data5": "3 Months",
                        "data6": "6 Months"
                    },
                    {
                        "id": "1",
                        "title": "AMOUNT",
                        "data1": "one",
                        "data2": "one",
                        "data3": "one",
                        "data4": "one",
                        "data5": "one",
                        "data6": "one"
                    },
                    {
                        "id": "1",
                        "title": "GST  (18%)",
                        "data1": "one",
                        "data2": "one",
                        "data3": "one",
                        "data4": "one",
                        "data5": "one",
                        "data6": "one"
                    },
                    {
                        "id": "1",
                        "title": "TOTAL",
                        "data1": "one",
                        "data2": "one",
                        "data3": "one",
                        "data4": "one",
                        "data5": "one",
                        "data6": "one"
                    }
                ]
            }
        }
          ],
          "serviceCost": "Service charges are excluding taxes. 18% GST will be charged on the package amount."
      }
  
  
      export const FutureData =   {
          "title": "Future",
          "cate": "equity",
          "description": "description",
          "mainImage": "mainImage",
          "pageUrl": "Future",
          "cashData": [
            {
            "cashDataInfo": {
                "id": "1",
                "title": "SIP",
                "body": [
                    "<p>This package is suitable for those who want to invest a certain amount in stocks every month and gain good returns holding suggested stock for long term i.e. 1 year or more. It is a tailor made suggestion to suit every individual investor according to their own investing and risk to reward appetite.</p>",
                   
                    "<ul><li>	5 - 10 stocks suggested every month</li><li>	1 year or more average holding period per entry</li><li>	Calls through Web Login / App.</li><li>	20-50% average return per call</li><li>	Assistance in your existing portfolio to minimize losses</li><li>	Invest not more than 20% of your capital of that month in a single call</li></ul>",

                ]
            },
            "tableData": {
                "title": "Intraday Cash",
                "tableDataList": [
                    {
                        "id": "1",
                        "title": "INFY",
                        "data1": "INFY",
                        "data2": "1274",
                        "data3": "15%",

                    },
                    {
                        "id": "2",
                        "title": "AMOUNT",
                        "data1": "WIPRO",
                        "data2": "380",
                        "data3": "15%",

                    },
                    {
                        "id": "3",
                        "title": "GST  (18%)",
                        "data1": "DLINK INDIA",
                        "data2": "229   ",
                        "data3": "10%",

                    },
                    {
                        "id": "4",
                        "title": "TOTAL",
                        "data1": "TATA STEEL",
                        "data2": "110",
                        "data3": "20%",

                    },
                    {
                        "id": "5",
                        "title": "TOTAL",
                        "data1": "HINDALCO",
                        "data2": "418",
                        "data3": "10%",

                    },
                    {
                        "id": "6",
                        "title": "TOTAL",
                        "data1": "PVR",
                        "data2": "1400",
                        "data3": "5%",

                    },
                    {
                        "id": "7",
                        "title": "TOTAL",
                        "data1": "JUSTDAIL",
                        "data2": "717",
                        "data3": "10%",

                    }
                ]
            }
        }
          ],
          "serviceCost": "Service charges are excluding taxes. 18% GST will be charged on the package amount."
      }
  export const StockIndexData =  {
          "title": "StockIndex",
          "cate": "equity",
          "description": "description",
          "mainImage": "mainImage",
          "pageUrl": "StockIndex",
          "cashData": [
              {
                  "cashDataInfo": {
                      "id": "1",
                      "title": "Stock Option + Index Option",
                      "body": [
                          "<p>This package is suitable for the conservative trader, who looks to trade in Stock Option/Index Option with low risk. We provide customized option strategies in stock options.</p>",
                          "<h6  >WHO SHOULD SUBSCRIBE THIS SERVICE?</h6>",
                          "<p>If your stock option/index option trading capacity is of 1-3 lots, then you should definitely subscribe this service.</p>",
                          "<h6  >SERVICES FEATURE</h6>",
                          "<ul><li>We will provide 1-2 Stock Option/Index Option tips every day.</li><li>We We will provide 20-22 Stock Option/Index Option tips in a month</li><li>Approx 10-15% return per call</li><li>Trading calls given via SMS.</li><li>Our Executives will call after market hours for trade verification and support.</li></ul>",
                          "<h6  >SAMPLE CALLS :</h6>",
                          "<p>(STOCK OPTION) BUY ADANIENT 160 CALL ABOVE 10 TGT 11.50, 13 SL 8 (NSE CASH)</p>",
                          "<h6  >FOLLOW UP CALLS :</h6>",
                          "<p>(STOCK OPTION) OUR 1ST TGT ACHIVED IN ADANIENT 160 CALL AT 11.50. OUR BUY CALL GIVEN AT 10.</p>",
                          "<p>(STOCK OPTION) OUR ALL TGT ACHIVED IN ADANIENT 160 CALL AT 13. OUR BUY CALL GIVEN AT 10.</p>"
                      ]
                  },
                  "tableData": {
                      "title": "Bonanza Cash",
                      "tableDataList": [
                          {
                              "id": "1",
                              "data1": "2 Days	",
                              "data2": "2,000",
                              "data3": "360",
                              "data4": "2,360"
                          },
                          {
                              "id": "2",
                              "data1": "7 Days	",
                              "data2": "4,000",
                              "data3": "720",
                              "data4": "4,720"
                          },
                          {
                              "id": "3",
                              "data1": "15 Days	",
                              "data2": "7,000",
                              "data3": "1,260",
                              "data4": "8,260"
                          },
                          {
                              "id": "4",
                              "data1": "1 Month	",
                              "data2": "12,000",
                              "data3": "2,160",
                              "data4": "14,160"
                          },
                          {
                              "id": "5",
                              "data1": "3 Months	",
                              "data2": "32,000	",
                              "data3": "5,760",
                              "data4": "37,760"
                          },
                          {
                              "id": "6",
                              "data1": "6 Months	",
                              "data2": "60,000",
                              "data3": "10,800",
                              "data4": "70,800"
                          }
                      ]
                  }
              },
              {
                  "cashDataInfo": {
                      "id": "2",
                      "title": "PREMIUM STOCK OPTION + INDEX OPTION",
                      "body": [
                          "<p>This package is suitable for HNI and risk taking traders who trades only in Stock option/Index Option tips.</p>",
                          "<h6  >WHO SHOULD SUBSCRIBE THIS SERVICE?</h6>",
                          "<p>If your stock future trading Index Option trading capacity is between 3 lots to 7 lots, then you should definitely subscribe this service.</p>",
                          "<h6  >SERVICE FEATURE</h6>",
                          "<ul><li>We will provide 1-2 Premium Stock option/Index Option tips every day.</li><li>We will provide 20-22 Premium Stock option/Index Option tips in a month</li><li>Approx 10-30% return per call</li><li>Important global market Updates</li><li>Important Updates of all the likely stocks in the News </li><li>Trading calls given via SMS and any other support through phone call.</li><li>Timely Entry and Exit</li><li>Blend of personalized & customized services offered to meet all your investment needs.</li></ul>",
                          "<h6  >SAMPLE CALLS :</h6>",
                          "<p>(PREMIUM OPTION) BUY AUROPHARMA 800 CALL ABOVE 20 TGT 24, 28 SL 16</p>",
                          "<h6  >FOLLOW UP CALLS :</h6>",
                          "<p>(PREMIUM OPTION) OUR 1ST TGT ACHIEVED IN AUROPHARMA AT 24. OUR BUY CALL GIVEN AT 20.</p>",
                          "<p>(PREMIUM OPTION) OUR ALL TGT ACHIEVED IN AUROPHARMA AT 28. OUR BUY CALL GIVEN AT 20.</p>"
                      ]
                  },
                  "tableData": {
                      "title": "Bonanza Cash",
                      "tableDataList": [
                          {
                              "id": "1",
                              "data1": "2 Days	",
                              "data2": "8,000",
                              "data3": "1,440",
                              "data4": "9,440"
                          },
                          {
                              "id": "2",
                              "data1": "7 Days	",
                              "data2": "17,000",
                              "data3": "3,060",
                              "data4": "20,060"
                          },
                          {
                              "id": "3",
                              "data1": "15 Days	",
                              "data2": "30,000",
                              "data3": "5,400",
                              "data4": "35,400"
                          },
                          {
                              "id": "4",
                              "data1": "1 Month	",
                              "data2": "50,000",
                              "data3": "9,000",
                              "data4": "59,000"
                          },
                          {
                              "id": "5",
                              "data1": "3 Months	",
                              "data2": "1,00,000",
                              "data3": "18,000",
                              "data4": "1,18,000"
                          },
                          {
                              "id": "6",
                              "data1": "6 Months	",
                              "data2": "1,25,000",
                              "data3": "22,500",
                              "data4": "1,47,500"
                          }
                      ]
                  }
              },
              {
                  "cashDataInfo": {
                      "id": "1",
                      "title": "HNI STOCK + INDEX OPTION",
                      "body": [
                          "<p>This service is exclusively designed for HNI clients, who wants to get higher returns compared to regular services. We take utmost care while providing calls, profit booking and timely exiting.</p>",
                          "<h6  >WHO SHOULD SUBSCRIBE THIS SERVICE?</h6>",
                          "<p>If your Stock Option/Index Option trading capacity is of 10 lots or above, then you should definitely subscribe this service.</p>",
                          "<h6  >SERVICES FEATURE</h6>",
                          "<ul><li>We will provide you 4-5 HNI Stock Option/Index Option tips in a month</li><li>We will provide 1-2 Premium Stock Option/Index Option tips every day.</li><li>Approx 10-30% return per call</li><li>Important global market Updates</li><li>Important Updates of all the likely stocks in the News</li><li>Trading calls given via SMS and any other support through phone call.</li><li>Timely Entry and Exit</li><li>Blend of personalized & customized services offered to meet all your investment needs.</li></ul>",
                          "<h6  >SAMPLE CALLS :</h6>",
                          "<p>(HNI OPTION) BUY RELIANCE 1100 CALL ABOVE 25 TGT 30, 35 SL 20</p>",
                          "<h6  >FOLLOW UP CALLS :</h6>",
                          "<p>(HNI OPTION) OUR 1ST TGT ACHIVED IN RELIANCE 1100 CALL AT 30. OUR BUY CALL GIVEN AT 25.</p>",
                          "<p>(HNI OPTION) OUR ALL TGT ACHIVED IN RELIANCE AT 35. OUR BUY CALL GIVEN AT 25.</p>"
                      ]
                  },
                  "tableData": {
                      "title": "PREMIUM STOCK + INDEX OPTION",
                      "tableDataList": [
                          {
                              "id": "1",
                              "data1": "2 Days	",
                              "data2": "8,000",
                              "data3": "1,440",
                              "data4": "9,440"
                          },
                          {
                              "id": "2",
                              "data1": "7 Days	",
                              "data2": "17,000",
                              "data3": "3,060",
                              "data4": "20,060"
                          },
                          {
                              "id": "3",
                              "data1": "15 Days	",
                              "data2": "30,000",
                              "data3": "5,400",
                              "data4": "35,400"
                          },
                          {
                              "id": "4",
                              "data1": "1 Month	",
                              "data2": "50,000",
                              "data3": "9,000",
                              "data4": "59,000"
                          },
                          {
                              "id": "5",
                              "data1": "3 Months	",
                              "data2": "1,00,000",
                              "data3": "18,000",
                              "data4": "1,18,000"
                          },
                          {
                              "id": "6",
                              "data1": "6 Months	",
                              "data2": "1,25,000",
                              "data3": "22,500",
                              "data4": "1,47,500"
                          }
                      ]
                  }
              }
          ],
          "serviceCost": "Service charges are excluding taxes. 18% GST will be charged on the package amount."
      }
      export const BullionsData = {
          "title": "Bullions",
          "cate": "mcx",
          "description": "description",
          "mainImage": "mainImage",
          "pageUrl": "Bullions",
          "cashData": [
            {
            "cashDataInfo": {
                "id": "1",
                "title": "STOCK FUTURE",
                "body": [
                    "<p>This package is suitable for small and medium traders, who trade in Future and options segment in Indian stock market.</p>",
                    "<h4>WHO SHOULD SUBSCRIBE THIS SERVICE?</h4>",
                    "<p>If your stock future trading capacity is of 1 lot, then you should definitely subscribe this service.</p>",
                    "<h4>SERVICES FEATURE</h4>",
                    "<ul><li>	We will provide 1-2 Stock future tips every day.</li><li>	We will provide 20-22 Stock Future tips in a month</li><li>	Approx 1% return per call</li><li>	Trading calls given via Web Login / App.</li><li>	Our Executives will call after market hours for trade verification and support.</li></ul>",
                    "<h4>SAMPLE CALLS :</h4>",
                    "<p>(F&O) BUY ADANIENT FUT ABOVE 160 TGT 161.50, 163 SL 158 (NSE CASH)</p>",
                    "<h4>FOLLOW UP CALLS : (F&O) OUR 1ST TGT ACHIVED IN ADANIENT FUT AT 161.50. OUR BUY CALL GIVEN AT 160</h4>",
                    "<p>(F&O) OUR ALL TGT ACHIVED IN ADANIENT FUT AT 163. OUR BUY CALL GIVEN AT 160.</p>"
                ]
            },
            "tableData": {
                "title": "Intraday Cash",
                "tableDataList": [
                    {
                        "id": "1",
                        "title": "PERIOD",
                        "data1": "2 Days",
                        "data2": "7 Days",
                        "data3": "15 Days",
                        "data4": "1 Months",
                        "data5": "3 Months",
                        "data6": "6 Months"
                    },
                    {
                        "id": "1",
                        "title": "AMOUNT",
                        "data1": "one",
                        "data2": "one",
                        "data3": "one",
                        "data4": "one",
                        "data5": "one",
                        "data6": "one"
                    },
                    {
                        "id": "1",
                        "title": "GST  (18%)",
                        "data1": "one",
                        "data2": "one",
                        "data3": "one",
                        "data4": "one",
                        "data5": "one",
                        "data6": "one"
                    },
                    {
                        "id": "1",
                        "title": "TOTAL",
                        "data1": "one",
                        "data2": "one",
                        "data3": "one",
                        "data4": "one",
                        "data5": "one",
                        "data6": "one"
                    }
                ]
            }
        }
          ],
          "serviceCost": "Service charges are excluding taxes. 18% GST will be charged on the package amount."
      }
      export const EnergyData =  {
          "title": "INDEX FUTURE",
          "cate": "mcx",
          "description": "description",
          "mainImage": "mainImage",
          "pageUrl": "Energy",
          "cashData": [
            {
            "cashDataInfo": {
                "id": "1",
                "title": "INDEX FUTURE",
                "body": [
                    "<p>This package is suitable for medium and big traders, who trade in index future segment in Indian stock market.</p>",
                    "<h4>WHO SHOULD SUBSCRIBE THIS SERVICE?</h4>",
                    "<p>If your trading capital is 60000+ and risk taking capacity is 15% and more.</p>",
                    "<h4>SERVICES FEATURE</h4>",
                    "<ul><li>	We will provide 1-2 index future tips every day.</li><li>	We will provide 20-22 Stock Future tips in a month</li><li>	Approx 1% return per call</li><li>	Trading calls given via Web Login / App.</li><li>	Our Executives will call after market hours for trade verification and support.</li></ul>",
                    "<h4>SAMPLE CALLS :</h4>",
                    "<p>Buy nifty may future ABOVE 18200 TGT 18350  18500 SL 18135</p>",
                    "<p>OUR ALL TGT ACHIVED NIFTY  FUT AT 18670. OUR BUY CALL GIVEN AT 18200.</p>"
                ]
            },
            "tableData": {
                "title": "Intraday Cash",
                "tableDataList": [
                    {
                        "id": "1",
                        "title": "PERIOD",
                        "data1": "2 Days",
                        "data2": "7 Days",
                        "data3": "15 Days",
                        "data4": "1 Months",
                        "data5": "3 Months",
                        "data6": "6 Months"
                    },
                    {
                        "id": "1",
                        "title": "AMOUNT",
                        "data1": "one",
                        "data2": "one",
                        "data3": "one",
                        "data4": "one",
                        "data5": "one",
                        "data6": "one"
                    },
                    {
                        "id": "1",
                        "title": "GST  (18%)",
                        "data1": "one",
                        "data2": "one",
                        "data3": "one",
                        "data4": "one",
                        "data5": "one",
                        "data6": "one"
                    },
                    {
                        "id": "1",
                        "title": "TOTAL",
                        "data1": "one",
                        "data2": "one",
                        "data3": "one",
                        "data4": "one",
                        "data5": "one",
                        "data6": "one"
                    }
                ]
            }
        }
          ],
          "serviceCost": "Service charges are excluding taxes. 18% GST will be charged on the package amount."
      }
      export const McxOptionData = {
          "title": "Mcx OPTION",
          "cate": "mcx",
          "description": "description",
          "mainImage": "mainImage",
          "pageUrl": "STOCK OPTION",
          "cashData": [
            {
            "cashDataInfo": {
                "id": "1",
                "title": "STOCK OPTION",
                "body": [
                    "<p>This package is suitable for the conservative trader, who look to trade in Stock Options with low risk. Option Trading Tips – We provide customized option strategies in stock options.</p>",
                    "<h4>WHO SHOULD SUBSCRIBE THIS SERVICE?</h4>",
                    "<p>If your stock option trading capacity is of 1-3 lots, then you should definitely subscribe this service.</p>",
                    "<h4>SERVICES FEATURE</h4>",
                    "<ul><li>	We will provide 1-2 Option tips every day.</li><li>	We will provide 20-22 Option tips in a month</li><li>	Approx 10-15% return per call</li><li>	Trading calls given via Web Login / App.</li><li>	Our Executives will call after market hours for trade verification and support.</li></ul>",
                    "<h4>SAMPLE CALLS :</h4>",
                    "<p>(OPTION) BUY ADANIENT 160 CALL ABOVE 10 TGT 11.50, 13 SL 8 (NSE CASH)</p>",
                    "<h4>FOLLOW UP CALLS :</h4>",
                    "<p>(OPTION) OUR 1ST TGT ACHIVED IN ADANIENT 160 CALL AT 11.50. OUR BUY CALL GIVEN AT 10.</p>",
                    "<p>(OPTION) OUR ALL TGT ACHIVED IN ADANIENT 160 CALL AT 13. OUR BUY CALL GIVEN AT 10.</p>"
                ]
            },
            "tableData": {
                "title": "Intraday Cash",
                "tableDataList": [
                    {
                        "id": "1",
                        "title": "PERIOD",
                        "data1": "2 Days",
                        "data2": "7 Days",
                        "data3": "15 Days",
                        "data4": "1 Months",
                        "data5": "3 Months",
                        "data6": "6 Months"
                    },
                    {
                        "id": "1",
                        "title": "AMOUNT",
                        "data1": "one",
                        "data2": "one",
                        "data3": "one",
                        "data4": "one",
                        "data5": "one",
                        "data6": "one"
                    },
                    {
                        "id": "1",
                        "title": "GST  (18%)",
                        "data1": "one",
                        "data2": "one",
                        "data3": "one",
                        "data4": "one",
                        "data5": "one",
                        "data6": "one"
                    },
                    {
                        "id": "1",
                        "title": "TOTAL",
                        "data1": "one",
                        "data2": "one",
                        "data3": "one",
                        "data4": "one",
                        "data5": "one",
                        "data6": "one"
                    }
                ]
            }
        }
          ],
          "serviceCost": "Service charges are excluding taxes. 18% GST will be charged on the package amount."
      }
      export const MetalData = {
          "title": "INDEX OPTION ",
          "cate": "mcx",
          "description": "description",
          "mainImage": "mainImage",
          "pageUrl": "INDEX OPTION ",
          "cashData": [
            {
            "cashDataInfo": {
                "id": "1",
                "title": "	INDEX OPTION ",
                "body": [
                    "<p>This package is suitable for the RISKY trader, who look to trade in INDEX Options with low risk. Option Trading Tips – We provide customized option strategies in stock options.</p>",
                    "<h4>WHO SHOULD SUBSCRIBE THIS SERVICE?</h4>",
                    "<p>If your stock option trading capacity is of 1-3 lots, then you should definitely subscribe this service.</p>",
                    "<h4>SERVICES FEATURE</h4>",
                    "<ul><li>	We will provide 1-2 Option tips every day.</li><li>	We will provide 20-22 Option tips in a month</li><li>	Approx 10-15% return per call</li><li>	Trading calls given via Web Login / App.</li><li>	Our Executives will call after market hours for trade verification and support.</li></ul>",
                    "<h4>SAMPLE CALLS :</h4>",
                    "<p>(OPTION) BUY NIFTY 18500CE MAY 18TH EXP ABOVE 160 TGT 190, 230 SL 149 (NSE CASH)</p>",
                    "<h4>FOLLOW UP CALLS :</h4>",
                    "<p>(OPTION) OUR 1ST TGT ACHIVED IN NIFTY 18500 CE, CALL GIVEN AT 160.</p>",
                    "<p>(OPTION) OUR ALL TGT ACHIVED IN NIFTY 18500 CE 230. OUR BUY CALL GIVEN AT 160.</p>"
                ]
            },
            "tableData": {
                "title": "Intraday Cash",
                "tableDataList": [
                    {
                        "id": "1",
                        "title": "PERIOD",
                        "data1": "2 Days",
                        "data2": "7 Days",
                        "data3": "15 Days",
                        "data4": "1 Months",
                        "data5": "3 Months",
                        "data6": "6 Months"
                    },
                    {
                        "id": "1",
                        "title": "AMOUNT",
                        "data1": "one",
                        "data2": "one",
                        "data3": "one",
                        "data4": "one",
                        "data5": "one",
                        "data6": "one"
                    },
                    {
                        "id": "1",
                        "title": "GST  (18%)",
                        "data1": "one",
                        "data2": "one",
                        "data3": "one",
                        "data4": "one",
                        "data5": "one",
                        "data6": "one"
                    },
                    {
                        "id": "1",
                        "title": "TOTAL",
                        "data1": "one",
                        "data2": "one",
                        "data3": "one",
                        "data4": "one",
                        "data5": "one",
                        "data6": "one"
                    }
                ]
            }
        }
          ],
          "serviceCost": "Service charges are excluding taxes. 18% GST will be charged on the package amount."
      }
      export const NormalMcxdata = {
          "title": "Normal mcx",
          "cate": "mcx",
          "description": "description",
          "mainImage": "mainImage",
          "pageUrl": "NormalMcx",
          "cashData": [
              {
                  "cashDataInfo": {
                      "id": "1",
                      "title": "mcx",
                      "body": [
                          "<p>This package is for small and medium trader who trade in Bullions Tips, Metals Tips and Energy Tips in mcx market.</p>",
                          "<h4>WHO SHOULD SUBSCRIBE THIS SERVICE?</h4>",
                          "<p>If your intraday trading volume is of 1 lot in mcx market, then you should definitely subscribe this service.</p>",
                          "<h4>SERVICES FEATURE</h4>",
                          "<ul><li>We will provide 1-2 mcx tips every day</li><li>We will provide 20-22 mcx calls in a month</li><li>Approx 80-100 pts target return in mcx Gold tips</li><li>Approx 1% return in metals tips and energy tips per call</li><li>Trading tips given via Web Login / App.</li><li>Our Executives will call after market hours for trade verification and support</li></ul>",
                          "<h4>SAMPLE CALLS :</h4>",
                          "<p>(BULLION) BUY GOLD ABOVE 29800 TGT 29880, 29960 SL 29700</p>",
                          "<h4>FOLLOW UP CALLS :</h4>",
                          "<p>(BULLION) OUR 1ST TGT ACHIVED IN GOLD AT 29880. OUR BUY CALL GIVEN AT 29800.</p>",
                          "<p>(BULLION) OUR ALL TGT ACHIVED IN GOLD AT 29960. OUR BUY CALL GIVEN AT 29800.</p>"
                      ]
                  },
                  "tableData": {
                      "title": "MCX",
                      "tableDataList": [
                          {
                              "id": "1",
                              "data1": "2 Days",
                              "data2": "3,000",
                              "data3": "540",
                              "data4": "3,540"
                          },
                          {
                              "id": "2",
                              "data1": "7 Days",
                              "data2": "5,000",
                              "data3": "900",
                              "data4": "5,900"
                          },
                          {
                              "id": "3",
                              "data1": "15 Days",
                              "data2": "9,000",
                              "data3": "1620",
                              "data4": "10,620"
                          },
                          {
                              "id": "4",
                              "data1": "1 Month",
                              "data2": "15,000",
                              "data3": "2700",
                              "data4": "17,700"
                          },
                          {
                              "id": "5",
                              "data1": "3 Months",
                              "data2": "40,000",
                              "data3": "7200",
                              "data4": "47,200"
                          },
                          {
                              "id": "6",
                              "data1": "6 Months",
                              "data2": "70,000",
                              "data3": "12600",
                              "data4": "82,600"
                          }
                      ]
                  }
              },
              {
                  "cashDataInfo": {
                      "id": "2",
                      "title": "BONANZA mcx",
                      "body": [
                          "<p>This package is suitable for moderate investors, who look for continues informed view in mcx market.</p>",
                          "<h4>WHO SHOULD SUBSCRIBE THIS SERVICE?</h4>",
                          "<p>If your intraday volume is of 2 lots in mcx market and want advantage of Semi Premium, then you should definitely subscribe this service.</p>",
                          "<h4>SERVICES FEATURE</h4>",
                          "<ul><li>We will provide 1-2 Bonanza mcx tips every day.</li><li>We will provide 20-22 Bonanza mcx tips in a month</li><li>Approx 1-2% return per call</li><li>Important global market Updates</li><li>Important Updates of all mcx Market News</li><li>Trading calls given via Web Login / App. and any other support through phone call.</li><li>Timely Entry and Exit</li></ul>",
                          "<h4>SAMPLE CALLS :</h4>",
                          "<p>BONANZA mcx) BUY GOLD ABOVE 29800 TGT 29890, 29980 SL 29700</p>",
                          "<h4>FOLLOW UP CALLS :</h4>",
                          "<p>(BONANZA mcx) OUR 1ST TGT ACHIVED IN GOLD AT 29890. OUR BUY CALL GIVEN AT 29800.</p>",
                          "<p>(BONANZA mcx) OUR ALL TGT ACHIVED IN GOLD AT 29980. OUR BUY CALL GIVEN AT 29800.</p>"
                      ]
                  },
                  "tableData": {
                      "title": "BONANZA MCX",
                      "tableDataList": [
                          {
                              "id": "1",
                              "data1": "2 Days",
                              "data2": "4,000",
                              "data3": "720",
                              "data4": "4,720"
                          },
                          {
                              "id": "2",
                              "data1": "7 Days",
                              "data2": "8,000",
                              "data3": "1440",
                              "data4": "9,440"
                          },
                          {
                              "id": "3",
                              "data1": "15 Days",
                              "data2": "14,000",
                              "data3": "2520",
                              "data4": "16,520"
                          },
                          {
                              "id": "4",
                              "data1": "1 Months",
                              "data2": "25,000",
                              "data3": "4500",
                              "data4": "29,500"
                          },
                          {
                              "id": "5",
                              "data1": "3 Months",
                              "data2": "70,000",
                              "data3": "12600",
                              "data4": "82,600"
                          },
                          {
                              "id": "6",
                              "data1": "6 Months",
                              "data2": "1,00,000",
                              "data3": "18000",
                              "data4": "1,18,000"
                          }
                      ]
                  }
              },
              {
                  "cashDataInfo": {
                      "id": "3",
                      "title": "PREMIUM mcx",
                      "body": [
                          "<p>This package is for HNI and risk taking trader who trade in Bullions, Metal and Energy in mcx market.</p>",
                          "<h4>WHO SHOULD SUBSCRIBE THIS SERVICE?</h4>",
                          "<p>If your trading lot capacity is between 3 lots to 7 lots in mcx Market, then you should definitely subscribe this service.</p>",
                          "<h4>SERVICES FEATURE:</h4>",
                          "<ul><li>We will provide 1-2 Premium mcx Tips every day.</li><li>We will provide 20-22 Premium mcx calls in a month</li><li>Approx 100-150 Pts target in Gold</li><li>Approx 1-2% return in Metals Tips and Energy TIps</li><li>Important global market Updates</li><li>Important Updates of all commodities market News</li><li>Trading calls given via Web Login / App. and any other support through phone call.</li><li>Timely Entry and Exit</li><li>Blend of personalized & customized services offered to meet all your investment</li></ul>",
                          "<h4>SAMPLE CALLS :</h4>",
                          "<p>(PREMIUM mcx) BUY GOLD ABOVE 29800 TGT 29900, 30000 SL 29700</p>",
                          "<h4>FOLLOW UP CALLS :</h4>",
                          "<p>(PREMIUM mcx) OUR 1ST TGT ACHIVED IN GOLD AT 29900. OUR BUY CALL GIVEN AT 29800.</p>",
                          "<p>(PREMIUM mcx) OUR ALL TGT ACHIVED IN GOLD AT 30000. OUR BUY CALL GIVEN AT 29800.</p>"
                      ]
                  },
                  "tableData": {
                      "title": "PREMIUM MCX",
                      "tableDataList": [
                          {
                              "id": "1",
                              "data1": "2 Days",
                              "data2": "8,000",
                              "data3": "1440",
                              "data4": "9,440"
                          },
                          {
                              "id": "2",
                              "data1": "7 Days",
                              "data2": "17,000",
                              "data3": "3060",
                              "data4": "20,060"
                          },
                          {
                              "id": "3",
                              "data1": "15 Days",
                              "data2": "30,000",
                              "data3": "5400",
                              "data4": "35,400"
                          },
                          {
                              "id": "4",
                              "data1": "1 Months",
                              "data2": "55,000",
                              "data3": "9900",
                              "data4": "64,900"
                          },
                          {
                              "id": "5",
                              "data1": "3 Months",
                              "data2": "1,00,000",
                              "data3": "18000",
                              "data4": "1,18,000"
                          },
                          {
                              "id": "6",
                              "data1": "6 Months",
                              "data2": "1,25,000",
                              "data3": "22500",
                              "data4": "1,47,500"
                          }
                      ]
                  }
              },
              {
                  "cashDataInfo": {
                      "id": "4",
                      "title": "HNI mcx",
                      "body": [
                          "<p>This service is exclusively designed for HNI clients, who want to gain higher returns in Bullion, Metal and Energy, when compared to regular services.</p>",
                          "<h4> WHO SHOULD SUBSCRIBE THIS SERVICE?</h4>",
                          "<p>If your trading lot capacity is of 10 lots or above in Gold, Silver, Metal and energy, then you should definitely subscribe this service.</p>",
                          "<h4>SERVICES FEATURE:</h4>",
                          "<ul><li>We will provide you 4-5 HNI mcx Tips in a month</li><li>We will provide 1-2 Premium mcx tips every day.</li><li>Approx 100-200 Pts target in Gold tips.</li><li>Approx 1-2% return on Metals tips and Energy tips</li><li>Important global market Updates</li><li>Important Updates of all the likely commodities in the News</li><li>Trading calls given via Web Login / App. and any other support through phone call.</li><li>Timely Entry and Exit</li><li>Blend of personalized & customized services offered to meet all your investment needs.</li></ul>",
                          "<h4>SAMPLE CALLS :</h4>",
                          "<p>(HNI mcx) BUY GOLD ABOVE 29800 TGT 29920, 30040 SL 29680</p>",
                          "<h4>FOLLOW UP CALLS :</h4>",
                          "<p>(HNI mcx) OUR 1ST TGT ACHIVED IN GOLD AT 29920. OUR BUY CALL GIVEN AT 29800.</p>",
                          "<p>(HNI mcx) OUR ALL TGT ACHIVED IN GOLD AT 30040. OUR BUY CALL GIVEN AT 29800.</p>"
                      ]
                  },
                  "tableData": {
                      "title": "HNI MCX",
                      "tableDataList": [
                          {
                              "id": "1",
                              "data1": "2 Days",
                              "data2": "15,000",
                              "data3": "2700",
                              "data4": "17,700"
                          },
                          {
                              "id": "2",
                              "data1": "7 Days",
                              "data2": "20,000",
                              "data3": "3600",
                              "data4": "23,600"
                          },
                          {
                              "id": "3",
                              "data1": "15 Days",
                              "data2": "37,500",
                              "data3": "6750",
                              "data4": "44,250"
                          },
                          {
                              "id": "4",
                              "data1": "1 Months",
                              "data2": "70,000",
                              "data3": "12600",
                              "data4": "82,600"
                          },
                          {
                              "id": "5",
                              "data1": "3 Months",
                              "data2": "125000",
                              "data3": "22500",
                              "data4": "147500"
                          }
                      ]
                  }
              }
          ],
          "serviceCost": "Service charges are excluding taxes. 18% GST will be charged on the package amount."
      }
      export const NcdexData =  {
          "title": "ncdex",
          "cate": "ncdex",
          "description": "description",
          "mainImage": "mainImage",
          "pageUrl": "Ncdex",
          "cashData": [
              {
                  "cashDataInfo": {
                      "id": "1",
                      "title": "ncdex",
                      "body": [
                          "<p>This package is for small and medium trader who trades in Agro calls in ncdex market.</p>",
                          "<h4>WHO SHOULD SUBSCRIBE THIS SERVICE?</h4>",
                          "<p>If your trading lot capacity is between 1 lots to 2 lots, then you should definitely subscribe this service.</p>",
                          "<h4>SERVICES FEATURE:</h4>",
                          "<ul><li>We will provide 1-2 ncdex tips every day.</li><li>We will provide 20-22 ncdex tips in a month</li><li>Trading calls given via Web Login / App.</li><li>Our Executives will call after market hours for trade verification and support.</li></ul>",
                          "<h4>SAMPLE CALLS :</h4>",
                          "<p>ncdex CALL) BUY SOYABEAN ABOVE 3290 TGT 3320, 3370 SL 3260</p>",
                          "<h4>FOLLOW UP CALLS :</h4>",
                          "<p>(ncdex CALL) OUR 1ST TGT ACHIVED IN SOYABEAN AT 3320. OUR BUY CALL GIVEN AT 3290.</p>",
                          "<p>(ncdex CALL) OUR ALL TGT ACHIVED IN SOYABEAN AT 3370. OUR BUY CALL GIVEN AT 3290.</p>"
                      ]
                  },
                  "tableData": {
                      "title": "NCDEX",
                      "tableDataList": [
                          {
                              "id": "1",
                              "data1": "7 Days",
                              "data2": "2,500",
                              "data3": "450",
                              "data4": "2,950"
                          },
                          {
                              "id": "2",
                              "data1": "15 Days",
                              "data2": "4,000",
                              "data3": "720",
                              "data4": "4,720"
                          },
                          {
                              "id": "3",
                              "data1": "1 Months",
                              "data2": "7,000",
                              "data3": "1260",
                              "data4": "8,260"
                          },
                          {
                              "id": "4",
                              "data1": "3 Months",
                              "data2": "18,000",
                              "data3": "3240",
                              "data4": "21,240"
                          },
                          {
                              "id": "5",
                              "data1": "6 Months",
                              "data2": "32,000",
                              "data3": "5760",
                              "data4": "37,760"
                          }
                      ]
                  }
              },
              {
                  "cashDataInfo": {
                      "id": "1",
                      "title": "Premium ncdex",
                      "body": [
                          "<p>This package is for HNI and risk taking traders who trades in Agro commodities like Cotton, Jeera, Dhaniya, Soyabean, RM Seed, Guarseed, Menthaoil etc in ncdex market.</p>",
                          "<h4>WHO SHOULD SUBSCRIBE THIS SERVICE?</h4>",
                          "<p>If your trading lot capacity is between 3 lots to 7 lots, then you should definitely subscribe this service.</p>",
                          "<h4>description</h4>",
                          "<ul><li>We will provide 1-2 Premium ncdex tips every day.</li><li>We will provide 20-22 Premium ncdex tips in a month</li><li>Approx 1-2% return in ncdex market tips.</li><li>Important global market Updates</li><li>Important Updates of all the likely Agro commodity News</li><li>Trading calls given via Web Login / App. and any other support through phone call.</li><li>Timely Entry and Exit</li><li>Blend of personalized & customized services offered to meet all your investment needs.</li></ul>",
                          "<h4>SAMPLE CALLS :</h4>",
                          "<p>(PREMIUM ncdex CALL) BUY JEERA ABOVE 19200 TGT 19450, 19700 SL 18940</p>",
                          "<h4>FOLLOW UP CALLS :</h4>",
                          "<p>(PREMIUM ncdex CALL) OUR 1ST TGT ACHIVED IN JEERA AT 19450. OUR BUY CALL GIVEN AT 19200.</p>",
                          "<p>(PREMIUM ncdex CALL) OUR ALL TGT ACHIVED IN JEERA AT 19700. OUR BUY CALL GIVEN AT 19200.</p>"
                      ]
                  },
                  "tableData": {
                      "title": "PREMIUM NCDEX",
                      "tableDataList": [
                          {
                              "id": "1",
                              "data1": "2 DAYS",
                              "data2": "4,000",
                              "data3": "720",
                              "data4": "4,720"
                          },
                          {
                              "id": "2",
                              "data1": "7 DAYS",
                              "data2": "8,000",
                              "data3": "1,440",
                              "data4": "9,440"
                          },
                          {
                              "id": "3",
                              "data1": "15 DAYS",
                              "data2": "14,000",
                              "data3": "2,520",
                              "data4": "16,520"
                          },
                          {
                              "id": "4",
                              "data1": "1 MONTH",
                              "data2": "25,000",
                              "data3": "4,500",
                              "data4": "29,500"
                          },
                          {
                              "id": "5",
                              "data1": "3 MONTHS",
                              "data2": "70,000",
                              "data3": "12,600",
                              "data4": "82,600"
                          },
                          {
                              "id": "6",
                              "data1": "6 MONTHS",
                              "data2": "1,00,000",
                              "data3": "18,000",
                              "data4": "1,18,000"
                          }
                      ]
                  }
              }
          ],
          "serviceCost": "Service charges are excluding taxes. 18% GST will be charged on the package amount."
      }