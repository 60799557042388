 

const teamMembers = [
  {
    id: 1,
    name: 'KARTHIK HATTI',
    role: 'CEO',
    
  },
  {
    id: 2,
    name: 'SUKESH J ',
    role: 'CHIEF FINANCIAL OFFICER',
    
  },
  {
    id: 3,
    name: 'SUSHMA P ',
    role: 'DEPUTY MANAGER',
    
  },
  {
    id: 4,
    name: 'REVANTH T G',
    role: 'PORTFOLIO ANALYST',
    
  },
  {
    id: 5,
    name: 'MOHAN KUMAR GANDHI H S',
    role: 'EQUITY ANALYST',
    
  },
  {
    id: 6,
    name: 'CHETHAN C G',
    role: 'FINANCIAL ADMINISTRATOR',
    
  },
  {
    id:7,
    name:'NIVETHA V ',
    role:'FINANCIAL MANAGER '
  }

];

const Team3 = () => {
  return (
    <>
      {teamMembers.map((member) => (
        <div
          key={member.id}
          className="col-lg-3 col-sm-6"
          data-aos="fade-up"
          data-aos-delay={`${member.id * 100}`}
        >
          <div className="team-block-two mt-40">
            <div className="img-meta position-relative">
              {/* <img
                width={312}
                height={281}
                src={member.imgSrc}
                alt={member.name}
                className="lazy-img team-img w-100"
              /> */}
              <div className="info text-center">
                <h5 className="tx-dark fs-20 mb-3">{member.name}</h5>
                <div className="tx-dark opacity-75">{member.role}</div>
              </div>
              {/* /.info */}
            </div>
            {/* /.img-meta */}
          </div>
          {/* /.team-block-two */}
        </div>
        /* End .col-3 */
      ))}
    </>
  );
};

export default Team3;
