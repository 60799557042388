import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import Card from 'react-bootstrap/Card';
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import testimonalData from '../../data/testimonial';

import { Pagination } from "swiper";
const data = testimonalData;

// import required modules

export default function TestimonalHomeSlider() {
  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        // pagination={{
        //   clickable: true,
        // }}
        grabCursor={true}
        loop={true}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 50,
          },
        }}
        modules={[]}
        className="mySwiper"
      >
        {
            data.map((tData)=>(
                <SwiperSlide>
                <Card >
      <Card.Img variant="top" src={tData.image} />
      <Card.Body>
        <Card.Title>{tData.author}</Card.Title>
        <Card.Text>
          {tData.text}
        </Card.Text>
       
      </Card.Body>
    </Card>
                </SwiperSlide>
            ))
        }

      </Swiper>
    </>
  );
}
