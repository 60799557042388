import Accordion from 'react-bootstrap/Accordion';

const accordionItems = [
  {
    id: 1,
    icon: '/images/icon/icon_108.svg',
    title: ' Unparalleled Expertise',
    content:
      'Our team consists of highly experienced investment professionals who possess deep knowledge and insights across various financial markets. With our extensive expertise, we stay ahead of the curve and provide you with intelligent investment strategies and solutions tailored to your specific needs.',
  },
  {
    id: 2,
    icon: '/images/icon/icon_109.svg',
    title: 'Holistic Approach',
    content:

'We believe in taking a holistic approach to investment and advisory services. By understanding your financial aspirations, risk tolerance, and time horizon, we develop comprehensive strategies that align with your goals. Our services encompass a wide range of investment options, including stocks, bonds, mutual funds, real estate, and alternative investments.'
  },
  {
    id: 3,
    icon: '/images/icon/icon_110.svg',
    title: 'Personalized Guidance',
    content:
"We recognize that every client is unique, with distinct financial circumstances and aspirations. That's why we take the time to listen and understand your individual goals, and then create a customized investment plan that reflects your preferences and risk appetite. With WayonC, you can expect tailored guidance that puts your interests first."
  },
  {
    id: 4,
    icon: '/images/icon/icon_110.svg',
    title: 'Rigorous Research and Analysis',
    content:
"Our investment decisions are backed by rigorous research and analysis. We employ cutting-edge tools and technology to evaluate market trends, conduct in-depth company analysis, and identify opportunities that have the potential for optimal returns. Our data-driven approach ensures that your investments are grounded in sound research and analysis."
  },
  {
    id: 5,
    icon: '/images/icon/icon_110.svg',
    title: 'Transparency and Trust',
    content:
"We prioritize transparency and trust in all our interactions. As our valued client, you can expect open communication, clear reporting, and regular updates on your investments. We are committed to building long-lasting relationships based on integrity, professionalism, and a genuine commitment to your financial success."
  },
  {
    id: 6,
    icon: '/images/icon/icon_110.svg',
    title: 'Comprehensive Advisory Services',
    content:
"Beyond investment management, we provide a range of advisory services designed to help you make informed financial decisions. Our team of experts can assist with retirement planning, tax strategies, estate planning, risk management, and more. We offer a holistic suite of services to support your financial well-being at every stage of life."
  }
];

const WhyChoose = () => {
  return (
    // <div className="accordion accordion-style-five md-mb-70" id="accordionOne">
    //   {accordionItems.map((item) => (
    //     <div className="accordion-item" key={item.id}>
    //       <div className="accordion-header" id={`heading${item.id}`}>
    //         <button
    //           className={`accordion-button ${item.id === 2 ? '' : 'collapsed'}`}
    //           type="button"
    //           data-bs-toggle="collapse"
    //           data-bs-target={`#collapse${item.id}`}
    //           aria-expanded={item.id === 2 ? 'true' : 'false'}
    //           aria-controls={`collapse${item.id}`}
    //         >
    //           <img src={item.icon} alt="" className="me-3" /> {item.title}
    //         </button>
    //       </div>
    //       <div
    //         id={`collapse${item.id}`}
    //         className={`accordion-collapse collapse${
    //           item.id === 2 ? ' show' : ''
    //         }`}
    //         aria-labelledby={`heading${item.id}`}
    //         data-bs-parent="#accordionOne"
    //       >
    //         <div className="accordion-body">
    //           <p>{item.content}</p>
    //         </div>
    //       </div>
    //     </div>
    //   ))}
    // </div>
    <>
       <Accordion defaultActiveKey="1">
       {accordionItems.map((item) => (
     <>
     <Accordion.Item eventKey={item.id}>
        <Accordion.Header>{item.title}</Accordion.Header>
        <Accordion.Body>
     {item.content}
        </Accordion.Body>
      </Accordion.Item>
     
  
    </>
      ))}
    </Accordion>
    </>
  );
};

export default WhyChoose;
