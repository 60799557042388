import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import {
    menuItems,
    pagesItems,
    portfolioItems,
    blogItems,
    resources,
  } from '../../data/menu';
  import {
    isActiveLink,
    isActiveParent,
    isActiveParentChaild,
  } from '../../utils/linkActiveChecker';
function NewHeader() {
  return (
    <Navbar expand="lg" className="">
      <Container fluid>
        {/* <Navbar.Brand href="#">Navbar scroll</Navbar.Brand> */}
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
           
          >
              <ul className="navbar-nav">
          <li className="d-block d-lg-none">
            <div className="logo">
              <Link  to="/" className="d-block text-decoration-none text-black" >
                <img
                  src="/wayOnC-logo.png"
                  alt="logo"
                  width={95}
                  height={30}
                />
              </Link>
            </div>
          </li>
          {/* End li */}

          <li className="nav-item dropdown mega-dropdown-md active text-decoration-none text-black">
            <Link  to="/" className="nav-link text-black">
              Home{' '}
            </Link>
          </li>
          <li className="nav-item dropdown mega-dropdown-md active">
            <Link  to="/about-us" className="nav-link text-black text-decoration-none text-black">
              About
            </Link>
          </li>

          {/* End li (home mega menu) */}

          <li className="nav-item  dropdown">
            <Link 
              className="nav-link dropdown-toggle text-black text-decoration-none text-black"
              to="/sub-services"
              role="button"
              data-bs-toggle="dropdown"
              data-bs-auto-close="outside"
              aria-expanded="false"
            >
              Services
            </Link>
            <ul className="dropdown-menu">
              {pagesItems.map((item, index) => (
                <li className="dropdown-submenu dropdown" key={index}>
                  <Link
                    className="dropdown-item dropdown-toggle text-decoration-none text-black"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false"
                    to="#"
                  >
                    <span>{item.title}</span>
                  </Link>
                  <ul className="dropdown-menu">
                    {item.subItems.map((subMenu, i) => (
                      <li key={i}>
                        <Link  to={subMenu.link} className="dropdown-item text-decoration-none text-black">
                          <span>{subMenu.title}</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </li>

          <li className="nav-item  dropdown">
            <Link 
              className="nav-link dropdown-toggle text-decoration-none text-black"
              to="/blogs"
              role="button"
              data-bs-toggle="dropdown"
              data-bs-auto-close="outside"
              aria-expanded="false"
            >
              Resources{' '}
            </Link>
            <ul className="dropdown-menu">
              {resources.map((item, index) => (
                <li key={index}>
                  <Link  to={item.link} className="dropdown-item text-decoration-none text-black">
                    <span>{item.name}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </li>
          {/* End li (pages) */}

          {/* <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              to="#"
              role="button"
              data-bs-toggle="dropdown"
              data-bs-auto-close="outside"
              aria-expanded="false"
            >
              Portfolio
            </a>
            <ul className="dropdown-menu">
              {portfolioItems.map((item, index) => (
                <li key={index}>
                  <Link  to={item.link} className="dropdown-item">
                    <span>{item.name}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </li> */}

          {/* End li (portfolio) */}

          {/* <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              to="#"
              role="button"
              data-bs-toggle="dropdown"
              data-bs-auto-close="outside"
              aria-expanded="false"
            >
              Blog
            </a>
            <ul className="dropdown-menu">
              {blogItems.map((blog, index) => (
                <li key={index}>
                  <Link  to={blog.link} className="dropdown-item">
                    <span>{blog.text}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </li> */}

          <li className="nav-item">
            <Link  className="nav-link" to="/contact-us" role="button">
              Contact
            </Link>
          </li>
          {/* End li (contact) */}
        </ul>
          </Nav>
        
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NewHeader;