module.exports = [
  {
    id: 1,
    imageSrc: "/images/blog/blog.jpeg",
    date: "23 Apr, 2022",
    title: "A Discount Cartridge dsu is Better Ever.",
    delay: "100",
  },
  {
    id: 2,
    imageSrc: "/images/blog/blog.jpeg",
    date: "14 March, 2022",
    title: "Truck Side Advertising Isn It Time for action.",
    delay: "200",
  },
  {
    id: 3,
    imageSrc: "/images/blog/blog.jpeg",
    date: "27 Feb, 2022",
    title: "A Discount Cartridge dsu is Better Ever.",
    delay: "300",
  },
  {
    id: 4,
    tag: "Education",
    tagStyle: { background: "#FFEAC9" },
    title: "Internet advertising what Went wrong.",
    description: "Having a home based business is a wonderful asset to your...",
    imageSrc: "/images/blog/blog.jpeg",
    delay: "100",
  },
  {
    id: 5,
    tag: "Charity",
    tagStyle: { background: "#C9F0FF" },
    title: "Internet advertising what Went wrong.",
    description: "Having a home based business is a wonderful asset to your...",
    imageSrc: "/images/blog/blog.jpeg",
    delay: "200",
  },
  {
    id: 6,
    tag: "Children",
    tagStyle: { background: "#FFEAEA" },
    title: "Internet advertising what Went wrong.",
    description: "Having a home based business is a wonderful asset to your...",
    imageSrc: "/images/blog/blog.jpeg",
    delay: "300",
  },
  {
    id: 7,
    imageSrc: "/images/blog/blog.jpeg",
    title: "Print, publishing qui visual ux quis layout mockups.",
    date: "23 Apr, 2022",
    delay: "100",
  },
  {
    id: 8,
    imageSrc: "/images/blog/blog.jpeg",
    title: "Make more productive working flow daily.",
    date: "23 Apr, 2022",
    delay: "200",
  },
  {
    id: 9,
    title:
      "Medieval origins to the digital era, everything there is to know. Excepteur sint occaecat cupidatat",
    date: "23 Apr, 2022",
    className: "text-style",
    delay: "300",
  },
  {
    id: 10,
    imageSrc: "/images/blog/blog.jpeg",
    title:
      "Quis nostrud exercitation ullamco laboris nisi ut aliquip commodo cons.",
    date: "23 Apr, 2022",
    delay: "400",
  },
  {
    id: 11,
    imageSrc: "/images/blog/blog.jpeg",
    title: "Designer’s Checklist for Every UX/UI Project.",
    date: "23 Apr, 2022",
    delay: "500",
  },
  {
    id: 12,
    imageSrc: "/images/blog/blog.jpeg",
    date: "23 Apr, 2022",
    title: "A Discount Cartridge dsu is Better Ever.",
    delay: "100",
  },
  {
    id: 13,
    imageSrc: "/images/blog/blog.jpeg",
    date: "23 Apr, 2022",
    title: "Truck Side Advertising Isn It Time for action.",
    delay: "200",
  },
  {
    id: 14,
    imageSrc: "/images/blog/blog.jpeg",
    date: "23 Apr, 2022",
    title: "Make Productive Day with flow app.",
    delay: "100",
  },
  {
    id: 15,
    imageSrc: "/images/blog/blog.jpeg",
    date: "23 Apr, 2022",
    title: "Designer’s Checklist for Every UX Project.",
    delay: "200",
  },
  {
    id: 16,
    imageSrc: "/images/blog/blog.jpeg",
    date: "23 Apr, 2022",
    title: "A Discount Cartridge dsu is Better Ever.",
    delay: "100",
  },
  {
    id: 17,
    imageSrc: "/images/blog/blog.jpeg",
    date: "23 Apr, 2022",
    title: "Truck Side Advertising Isn It Time for action.",
    delay: "200",
  },
  {
    id: 18,
    imageSrc: "/images/blog/blog.jpeg",
    date: "23 Apr, 2022",
    delay: "100",
  },
  {
    id: 19,
    imageSrc: "/images/blog/blog.jpeg",
    date: "23 Apr, 2022",
    title: "Designer’s Checklist for Every UX Project.",
    delay: "200",
  },
  {
    id: 20,
    tag: "Design",
    title: "Until recently, the prevailing view assumed lorem ipsum was..",
    date: "18 Jul 2022",
    delay: "100",
  },
  {
    id: 21,
    tag: "Development",
    title: "Print, publishing qui visual ux quis layout mockups.",
    date: "18 Jul 2022",
    delay: "200",
  },
  {
    id: 22,
    tag: "Design",
    title: "Until recently, the prevailing view assumed lorem ipsum was..",
    date: "18 Jul 2022",
    delay: "100",
  },
  {
    id: 23,
    tag: "Development",
    title: "Print, publishing qui visual ux quis layout mockups.",
    date: "18 Jul 2022",
    delay: "200",
  },
  {
    id: 24,
    tag: "Development",
    title: "Print, publishing qui visual ux quis layout mockups.",
    date: "18 Jul 2022",
    delay: "100",
  },
  {
    id: 25,
    tag: "Design",
    title: "Until recently, the prevailing view assumed lorem ipsum was..",
    date: "18 Jul 2022",
    delay: "200",
  },
  {
    id: 26,
    imageSrc: "/images/blog/blog.jpeg",
    tag: "Design",
    title: "Speaking remotely at WordCamp US.",
    date: "18 Jul 2022",
    delay: "100",
  },
  {
    id: 27,
    imageSrc: "/images/media/img_83.jpg",
    tag: "PROPERTY",
    title: "Print, publishing qui visual ux quis layout mockups.",
    date: "18 Jul 2022",
    delay: "200",
  },
  {
    id: 28,
    image: "url(/images/blog/blog.jpeg)",
    imageSrc: "/images/blog/blog.jpeg",
    logo: "/images/logo/Plogo-32.jpeg",
    icon: "/images/icon/icon_87.svg",
    title:
      "Seattle Opera Simplifies Performance Planning with deski eSignature.",
    videoId: "Vsq1_kewchQ",
  },
  {
    id: 29,
    image: "url(/images/blog/blog.jpeg)",
    imageSrc: "/images/blog/blog.jpeg",
    logo: "/images/logo/Plogo-33.jpeg",
    icon: "/images/icon/icon_87.svg",
    title: "How DocuSign CLM Helps Celonis Scale Its Global Business.",
    videoId: "J4HLbKkcheE",
  },

  {
    id: 30,
    imageSrc: "/images/blog/blog.jpeg",
    tag: "Technology",
    tagBgColor: "#FFEAC9",
    title: "A Discount Cartridge dsu is Better Ever.",
  },
  {
    id: 31,
    imageSrc: "/images/blog/blog.jpeg",
    tag: "Design",
    tagBgColor: "#CEEBF4",
    title: "Good design is the key of business.",
  },
  {
    id: 32,
    imageSrc: "/images/blog/blog.jpeg",
    tag: "Support",
    tagBgColor: "#FFDFDF",
    title: "Client satisfaction is the goal of business success.",
  },
  {
    id: 33,
    imageSrc: "/images/blog/blog.jpeg",
    date: "23 Apr, 2022",
    title: "A Discount Cartridge dsu is Better Ever.",
  },
  {
    id: 34,
    imageSrc: "/images/blog/blog.jpeg",
    date: "14 march, 2022",
    title: "Truck Side Advertising Isn It Time for action.",
  },
  {
    id: 35,
    imageSrc: "/images/blog/blog.jpeg",
    date: "27 feb, 2022",
    title: "A Discount Cartridge dsu is Better Ever.",
  },
];
