import DefaulHeader from '../components/header/DefaulHeader';
import DefaultFooter from '../components/footer/DefaultFooter';
import Pagination from '../components/blog/Pagination';
import SearchBox from '../components/blog/SearchBox';
import Category from '../components/blog/Category';
import RecentPost from '../components/blog/RecentPost';
import BannerPost from '../components/blog/BannerPost';
import Blog2 from '../components/blog/Blog2';
 

const Blogs = () => {
  return (
    <>
      {/* <!-- 
      =============================================
      Theme Default Menu
      ============================================== 	
      --> */}
      <DefaulHeader />

      {/* 
			=============================================
			Feature Section Fifty One
			============================================== 
			*/}
      <div className="fancy-feature-fiftyOne position-relative pt-200">
        <div className="container">
          <div className="row">
            <div className="col-lg-7" data-aos="fade-right">
              <div className="title-style-five mb-65 lg-mb-40">
                <div className="sc-title-two fst-italic position-relative">
                  Blog
                </div>
                <h2 className="main-title fw-500 tx-dark">
                  Check our inside news &amp; update.
                </h2>
              </div>
            </div>
          </div>
        </div>
        {/* /.container */}
        <img
          src="/images/shape/shape_172.svg"
          alt="shape"
          className="lazy-img shapes shape-two"
        />
      </div>

      {/*
			=====================================================
				Blog Section Five
			=====================================================
			*/}
      <div className="blog-section-five mt-70 lg-mt-30">
        <div className="container">
          <div className="border-bottom pb-130 lg-pb-60">
            <div className="row gx-xl-5">
              <div className="col-lg-12">
                <div className="blog-meta-wrapper pe-xxl-5">
                  <div className="row">
                    <Blog2 />
                  </div>
                </div>
                {/* /.blog-meta-wrapper */}
                {/* <div className="page-pagination-one pt-15">
                  <Pagination />
                </div> */}
              </div>
              {/* End .col-lg-8 */}
{/* 
              <div className="col-lg-4 col-md-8">
                <div className="blog-sidebar md-mt-70 d-none">
                  <div className="blog-sidebar-search mb-55 md-mb-40">
                    <SearchBox />
                  </div>
                  /.blog-sidebar-search
 
                  <div className="blog-sidebar-category mb-60 md-mb-50 d-none">
                    <h4 className="sidebar-title">Category</h4>
                    <Category />
                  </div>
                  /.blog-sidebar-category

                  <div className="sidebar-recent-news mb-60 md-mb-50">
                    <h4 className="sidebar-title">Recent News</h4>
                    <RecentPost />
                  </div>
                  /.sidebar-recent-news

                  <BannerPost className=" d-none"/>
                  /.sidebar-banner-add
                </div>
                /.blog-sidebar
              </div> */}
            </div>
          </div>
        </div>
      </div>

      {/* 
        =============================================
        Contact Section One
        ============================================== 
        */}
      <DefaultFooter />
    </>
  );
};

export default Blogs;
