import React from 'react';
import Logout from './logout';
import ProfileButton from './profileButton';
import ReceivedFormsButton from './receivedFormsButton';
import Investors from './Investors';
import TodayEarners from './TodayEarners';
 import { Link } from 'react-router-dom';
 

const Sidebar = () => {
  return (
    <>
      <div className="sidebar">
        <div className="sidebar-header">
          <Link className="text-decoration-none text-black" to="/admin/dashboard">
            <img src="/wayOnC-logo.png" alt="Logo" />
          </Link>
        </div>
        <div className="sidebar-content-parent">
          <div className="sidebar-content-child-header">
            <ul>
              <Link className="text-decoration-none text-black" to="/admin/dashboard" className="active">
                <li>DashBord</li>
              </Link>
              <Link className="text-decoration-none text-black" to="/admin/receivedforms">
                <li>Received forms</li>
              </Link>
              <Link className="text-decoration-none text-black" to="/admin/clientsData">
                <li>Investors</li>
              </Link>
              <Link className="text-decoration-none text-black" to="/admin/todayEarners">
                <li>TodayEarners</li>
              </Link>
              <Link className="text-decoration-none text-black" to="/admin/requests">
                <li>CheckOuts</li>
              </Link>
              <Link className="text-decoration-none text-black" to="/admin/backups">
                <li>Backups</li>
              </Link>
              <Link className="text-decoration-none text-black" to="/admin/addUser">
                <li>Users</li>
              </Link>
              <Link className="text-decoration-none text-black" to="/admin/agreements">
                <li>Inv Requests</li>
              </Link>
            </ul>
          </div>
          <div className="sidebar-content-child-footer">
            <ul>
              <Link className="text-decoration-none text-black" to="/admin/adminProfile">
                <li>Profile</li>
              </Link>
            </ul>
            <Logout />
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
