import DefaulHeader from "../components/header/DefaulHeader";
import DefaultFooter from "../components/footer/DefaultFooter";
import Partners from "../components/services/Partners";
import Faq from "../components/services/Faq";
import Testimonial from "../components/home-page/home-3/Testimonial";
import Block from "../components/services/Block";
import Team3 from "../components/team/Team3";
import { Link } from "react-router-dom";
import VideoBlock from "../components/about/VideoBlock";
import Counter from "../components/about/Counter";
import aboutData from "../data/about/about.json";

const myData = aboutData;

const AboutUsV1 = () => {
  const features = [
    { text: "Empowering Your Financial Success, One Investment at a Time." },
    { text: "Unleashing the Power of Your Investments for a Brighter Future." },
    {
      text: "Precision, Strategy, and Performance - The Foundations of Our Investment Approach.",
    },
    {
      text: "Together, we build lasting partnerships that stand the test of time, empowering your financial future.",
    },
    {
      text: "When you succeed, we celebrate. Your success is our driving force.",
    },
    { text: "Transforming Your Financial Vision into Reality" },
    { text: "Partner with Us for a Thriving Financial Future." },
  ];

  const starRating = Array(5)
    .fill()
    .map((_, index) => (
      <li key={index}>
        <i className="fa-solid fa-star" />
      </li>
    ));

  return (
    <>
      {/* <!-- 
      =============================================
      Theme Default Menu
      ============================================== 	
      --> */}
      <DefaulHeader />

      {/* 
			=============================================
				Feature Section Fifty One
			============================================== 
			*/}
      <div className="fancy-feature-fiftyOne position-relative pt-200">
        <div className="container">
          <div className="row">
            <div className="col-lg-7" data-aos="fade-right">
              <div className="title-style-five mb-65 md-mb-40">
                <div className="sc-title-two fst-italic position-relative">
                  More About us.
                </div>
                <h2 className="main-title fw-500 tx-dark">
                  Empower Your Wealth with WayonC
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-lg-4 ms-auto">
              {/* <img src='https://o.remove.bg/downloads/7b49cd4c-8456-4545-aadf-d26148ebb5f8/christian-wiediger-MAKEzgAQ6WU-unsplash-removebg-preview.png' alt='image'/> */}
            </div>
            <div className="col-xl-8 col-lg-9 ms-auto">
              <div className="ps-xxl-5" data-aos="fade-left">
                <h6 className="mb-30">Our History &amp; Goal.</h6>
                <p className="text-lg tx-dark">
                  Karthik Hatti, the founder of our company, is an experienced
                  professional in the field of stocks and investments. With a
                  strong background in finance and a passion for capital
                  markets, he has acquired extensive knowledge and
                  expertise in this domain. Throughout his career, he has
                  successfully navigated various market cycles, demonstrating an
                  ability to adapt and thrive in different market conditions. He
                  has a proven track record of delivering consistent returns and
                  managing risks effectively.
                </p>
                <div className="row">
                  <Counter />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /.container */}

        <img
          src="/images/shape/shape_171.svg"
          alt="shape"
          className="lazy-img shapes shape-one"
        />
        <img
          src="/images/shape/shape_172.svg"
          alt="shape"
          className="lazy-img shapes shape-two"
        />
      </div>
      {/* /.fancy-feature-fiftyOne */}

      {/* <!-- 
			=============================================
				Feature Section Fifty Two
			============================================== 
			--> */}
      {/* <VideoBlock /> */}
      {/* visiona and mision */}

      <div className="fancy-feature-thirtySeven mt-80 lg-mt-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-6" data-aos="fade-right">
              <div className="ps-xxl-5 ps-xl-5 ps-lg-5 ps-md-5 ps-sm-5 ps-xs-5">
                <h3 className="mb-30">Our Vision.</h3>
                <p className=" tx-dark">
                  At WayonC, our mission is to empower our clients with reliable
                  and insightful investment advisory services. We are dedicated
                  to helping our clients navigate the complex world of
                  investments, achieve their financial goals, and secure their
                  financial future. We strive to provide exceptional expertise,
                  personalized attention, and innovative solutions that exceed
                  our client &apos;s expectations.
                </p>
              </div>
            </div>
            <div className="col-lg-6" data-aos="fade-right">
              <div className="ps-xxl-5 ps-xl-5 ps-lg-5 ps-md-5 ps-sm-5 ps-xs-5">
                <h3 className="mb-30">Our Mission.</h3>
                <p className=" tx-dark">
                  Our vision at WayonC is to be recognized as a leading
                  investment advisory firm, renowned for our commitment to
                  excellence, integrity, and client-centric approach. We aspire
                  to be the trusted partner that our clients turn to for expert
                  advice, tailored investment strategies, and superior financial
                  outcomes. By leveraging our deep industry knowledge and
                  staying at the forefront of market trends.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* vision and mision */}
      {/* Features of invesstmnet  */}

      {myData.map((aboutData, i) => (
        <div className="fancy-feature-thirtySeven mt-0 lg-mt-60" key={i}>
          <div className="container">
            <div className="parent row">
              {aboutData.stakeList.stakeListInfo.map((stakeListData, i) => (
                <div className=" valued-service " key={i}>
                  <div className="row abcd">
                    <div className="col-md-6">
                      <h3 className="title">{stakeListData.title}</h3>
                      <p>{stakeListData.description}</p>
                    </div>
                    <div className="col-md-6">
                      <img
                        src={stakeListData.image}
                        className="ai-ig"
                        alt={stakeListData.title}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
      {/* Features of invesstmnet  */}

      {/* <!-- /.fancy-feature-fiftyTwo --> */}

      {/* 
			=============================================
				Feature Section Thirty Seven
			============================================== 
			*/}
      <div className="fancy-feature-thirtySeven mt-0 lg-mt-60">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div
                className="block-style-four pe-xxl-5 me-xxl-4 md-mb-70"
                data-aos=""
              >
                <div className="title-style-six">
                  <div className="sc-title-two text-uppercase">WHY US</div>
                  <h2 className="main-title fw-500 tx-dark">
                    Everything with Marketing.
                  </h2>
                </div>
                {/* /.title-style-ten */}
                <p className="fs-20 pt-20 pb-30 lg-pt-10 lg-pb-20">
                  {/* Turn your business into a sales machine today with lorem
                  Things go wrong have questions. */}
                </p>
                <ul className="style-none list-item">
                  {features.map((feature, index) => (
                    <li key={index}>{feature.text}</li>
                  ))}
                </ul>
                <div className="d-inline-flex align-items-center mt-35 lg-mt-20">
                  <ul className="d-flex style-none rating">{starRating}</ul>
                  <div className="fs-20 ms-2">
                    <strong className="fw-500 tx-dark">4.8</strong>
                    <span className="tx-dark fs-16 opacity-50">
                      (30k Reviews)
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* End .col */}

            <div className="col-xxl-5 col-lg-6 ms-auto">
              <Block />
            </div>
            {/* End .col */}
          </div>
        </div>
        {/* /.container */}
      </div>
      {/* /.fancy-feature-thirtySeven */}

      {/*
			=====================================================
				Team Section Two
			=====================================================
			*/}
      <div className="team-section-two position-relative pt-200 lg-pt-120">
        <div className="container">
          <div className="wrapper position-relative">
            <div className="row align-items-center">
              <div className="col-lg-5" data-aos="fade-right">
                <div className="title-style-six text-center text-lg-start pb-40 lg-pb-20 md-pb-10">
                  <h2 className="main-title fw-500 tx-dark">Our Team</h2>
                </div>
                {/* /.title-style-six */}
              </div>
            </div>
            {/* /.row */}
            <div className="row">
              <Team3 />
            </div>
            {/* /.row */}

            {/* <p
              className="cr-text text-center text-lg tx-dark mt-75 lg-mt-50"
              data-aos="fade-up"
            >
              Our nearly
              <span className="text-decoration-underline fw-500">8,000+</span>
              committed staff members are ready to help.
            </p> */}
            <div className="text-center md-mt-20">
              <Link
                href="# "
                className="btn-twentyTwo fw-500 tran3s text-decoration-none text-black"
                data-aos="fade-left"
              >
                View full team
              </Link>
            </div>
          </div>
          {/* /.wrapper */}
        </div>
        {/* /.container */}
        <img
          src="/images/shape/shape_172.svg"
          alt="shape"
          className="lazy-img shapes shape-one d-none d-xl-inline-block"
        />
      </div>
      {/* /.team-section-two */}

      {/*
			=====================================================
				Feedback Section Eleven
			=====================================================
			*/}
      <div
        className="feedback-section-eleven position-relative mt-50 pt-100 pb-70 lg-pt-70 lg-pb-50 d-none"
        data-aos="fade-up"
      >
        <div className="container">
          <div className="title-style-one text-center mb-50 lg-mb-20">
            <h2 className="main-title fw-500 tx-dark m0">Client Feedback</h2>
          </div>
        </div>
        {/* End .container */}
        <div className="inner-content">
          <div className="slider-wrapper">
            <div className="feedback_slider_seven">
              <Testimonial />
            </div>
            {/* /.feedback_slider_seven */}
          </div>
          {/* /.slider-wrapper */}
        </div>
        {/* /.inner-content */}
      </div>
      {/* /.feedback-section-eleven */}

      {/* 
			=============================================
				Feature Section Thirty Eight
			============================================== 
			*/}
      <div className="fancy-feature-thirtyEight mt-180 lg-mt-120 d-none">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="block-style-seven" data-aos="fade-right">
                <div className="title-style-six">
                  <div className="sc-title-two text-uppercase">SOLUTION</div>
                  <h2 className="main-title fw-500 tx-dark">
                    Digital solutions for business.
                  </h2>
                </div>
                {/* /.title-style-ten */}
                <p className="fs-20 pt-10 pb-30 lg-pb-20">
                  Do you have more question about our solution? Just send us a
                  message for help
                </p>
                <div className="btn-eighteen position-relative d-inline-block tx-dark">
                  <Link
                    to="/page-menu/about-us-v1"
                    className="fw-500 tran3s text-decoration-none text-black"
                  >
                    More About solution
                    <i className="fa-solid fa-angle-right" />
                  </Link>
                </div>
              </div>
              {/* /.block-style-seven */}
            </div>
            {/* End .col-lg-5 */}
            <div className="col-lg-6 ms-auto" data-aos="fade-left">
              <Faq />
            </div>
          </div>
        </div>
        {/* /.container */}

        <div className="partner-section-six position-relative mt-130 lg-mt-80">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5">
                <h3 className="title tx-dark text-center text-lg-start md-pb-10 m0">
                  <span>100+</span> Trusted Partners
                </h3>
              </div>
              <div className="col-xl-6 col-lg-7 ms-auto">
                <div className="logo-wrapper text-center d-flex justify-content-center justify-content-lg-between flex-wrap">
                  <Partners />
                </div>
                {/* /.logo-wrapper */}
              </div>
            </div>
          </div>
          {/* /.container */}
        </div>
      </div>
      {/* /.partner-section-six */}

      {/*
			=====================================================
				Fancy Short Banner Sixteen
			=====================================================
			*/}
      <div
        className="fancy-short-banner-sixteen mt-130 lg-mt-80"
        data-aos="fade-up"
      >
        <div className="container">
          <div className="bg-wrapper pt-65 pb-65 lg-pt-40 lg-pb-40">
            <div className="row">
              <div className="col-xl-10 col-md-11 m-auto">
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div className="text-wrapper text-center text-lg-start md-pb-30">
                      <div className="sc-title fs-18 pb-10">
                        Are You Have Any Investments..?
                      </div>
                      <h2 className="main-title fw-500 text-white m0">
                        Don’t hesitate to send us message.
                      </h2>
                    </div>
                  </div>
                  {/* End .col-6 */}

                  <div className="col-lg-5 ms-auto text-center text-lg-end">
                    <Link
                      to="/form/client-form"
                      className="btn-twentyOne fw-500 tran3s text-decoration-none text-black"
                    >
                      Get Started Today!
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /.bg-wrapper */}
        </div>
      </div>
      {/* /.fancy-short-banner-sixteen */}

      {/* 
        =============================================
        Contact Section One
        ============================================== 
        */}
      <DefaultFooter />
    </>
  );
};

export default AboutUsV1;
