import React from 'react';
 import { Link, useNavigate } from 'react-router-dom';
import ClientLogout from './ClientLogout';
import useDownloader from 'react-use-downloader';
 

const ClientSidebar = ({ userID, name, email, image, pdfDownload, path, clientId }) => {
  console.log(userID)
  const { download } = useDownloader();
  const router = useNavigate()
  // const clientHandle = ()=>{
  //    router(`/clientDashboard/${userID}/invest`);
  // }
  
  return (
    <>
      <div className="sidebar">
      <p className="tableClientEmail sidebarID"><i>Client ID : {clientId}</i></p>
        <div className="sidebar-header">
        
          {/* <Link className="text-decoration-none text-black" to={`/clientDashboard/${[userID]}`}>
           <img src="/wayOnC-logo.png" alt="Logo" />
          </Link> */}
          <Link className="text-decoration-none text-black" to={`/clientDashboard/${userID}`}>
            <img
              src={`http://res.cloudinary.com/dtjlq2uaq/image/upload/v1686306194/${image}`}
              alt="Logo"
              className="profile-img"
            />
          </Link>
        </div>
        <div className="sidebar-content-parent">
          <div className="sidebar-content-child-header">
            <ul>
              <Link className="text-decoration-none text-black active" to={`/clientDashboard/${userID}`} >
                <li>DashBoard</li>
              </Link>
              <Link className="text-decoration-none text-black" to={`/clientDashboard/${userID}/invest`} >
                <li>Invest</li>
              </Link>
              {/* <p onClick={clientHandle}>Investments </p> */}
              {/* <Link className="text-decoration-none text-black" to={`/clientDashboard/${userID}/invest`} >
                <li>Invest</li>
              </Link> */}
              {/* <Link className="text-decoration-none text-black" to={`clientDashboard/${userID}/profile`}> */}
              <Link className="text-decoration-none text-black" to={`/clientDashboard/${userID}/profile`}>
                <li>Profile</li>
              </Link>
              {pdfDownload && (
                <Link className="text-decoration-none text-black"
                  to={`http://res.cloudinary.com/dtjlq2uaq/image/upload/v1686306194/${path}`}
                >
                  <li>Agreement</li>
                </Link>
              )}
            </ul>
          </div>
          <div className="sidebar-content-child-footer">
            <Link 
              className="text-decoration-none text-black profileLink"
              to={`/clientDashboard/${userID}/profile`}
            >
              <p class="tableClientName">{name}</p>
              <p class="tableClientEmail">{email}</p>
            </Link>
            <ClientLogout />
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientSidebar;
