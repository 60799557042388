import { Link } from "react-router-dom";

const columns = [
  {
    title: "Links",
    links: [
      { label: "Home", url: "/" },
      { label: "Contact", url: "/contact-us" },
      { label: "About us", url: "/about-us" },
      { label: "Service", url: "/sub-services" },
      { label: "Blog", url: "/blogs" },
    ],
  },
  {
    title: "Support",
    links: [
      { label: "Intraday Trading Data", url: "/intraday-trading" },
      { label: "Share Market", url: "/share-market" },
      { label: "Commodity", url: "/commodity" },
      { label: "Share Trading", url: "/share-trading" },
      { label: "Stock Market", url: "/stock-market" },
      { label: "Mcx Optons", url: "/mcx-options" },
    ],
  },
  {
    title: "Products",
    links: [
      { label: "Commodity Trading", url: "/commodity" },
      { label: "Refund Policy", url: "/refund-policy" },
      { label: "Disclaimer", url: "/disclaimer" },
      { label: "Privacy Policy", url: "/privay-policy" },
      { label: "Terms And Conditions", url: "/terms-and-conditions" },
    ],
  },
];

const Footer2 = () => {
  return (
    <>
      {columns.map((column, index) => (
        <div className="col-md-3" key={index}>
          <h5 className="footer-title tx-dark fw-normal">{column.title}</h5>
          <ul className="footer-nav-link style-none">
            {column.links.map((link, index) => (
              <li key={index}>
                <Link className="text-decoration-none text-black" to={link.url}>{link.label}</Link>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </>
  );
};

export default Footer2;
