import React from 'react';
 import { Link } from 'react-router-dom';

function TodayEarners() {
  return (
    <div>
      <Link className="text-decoration-none text-black" href="/admin/todayEarners">Today Earners</Link>
    </div>
  );
}

export default TodayEarners;
