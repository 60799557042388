import React from 'react';
import Logout from './logout';
 import { Link } from 'react-router-dom';
 

const Sidebar = ({ name }) => {
  return (
    <>
      <div className="sidebar">
        <div className="sidebar-header">
          <Link className="text-decoration-none text-black" to="/user">
            <img src="/wayOnC-logo.png" alt="Logo" />
          </Link>
        </div>
        <div className="sidebar-content-parent">
          <div className="sidebar-content-child-header">
            <ul>
              <Link  to="/user" className="active text-decoration-none text-black">
                <li>DashBord</li>
              </Link>
              <Link className="text-decoration-none text-black" to="/user/receivedforms">
                <li>Received forms</li>
              </Link>
              <Link className="text-decoration-none text-black" to="/user/agreements">
                <li>Inv Requests</li>
              </Link>
            </ul>
          </div>
          <div className="sidebar-content-child-footer">
            <p class="tableClientName">{name}</p>
            <Logout />
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
